import { Button, Form, Input, Select, Table } from "antd";
import requiredRule from "../../../utils/formRules/requiredRule";
import { useState, useEffect } from "react";
import { ColumnsType } from "antd/es/table";
import { BuyUpQueryDataType } from "./types";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";
import { Dispatch, SetStateAction } from "react";
import { useReactiveVar } from "@apollo/client";
import { __currentUser__ } from "../../../graphql/policies";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import { ServiceTypes } from "../../../types/types";
import dayjs from "dayjs";
import ContentTitle from "../../../components/ui/ContentTitle";
import { useUpgradePaymentListQuery } from "../../../graphql/operations/clubApp/buyUp/get-buy-up-list";
import "./BuyUpQuery.scss";

interface BuyUpQueryProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}
const BuyUpQuery = ({
  setSelectedService,
  setSelectSubItem,
}: BuyUpQueryProps) => {
  const [form] = Form.useForm();
  const [dataDisplay, setDataDisplay] = useState<BuyUpQueryDataType[]>([]);
  const [market, setMarket] = useState("");
  const [showData, setShowData] = useState(false);
  const [memberNumber, setMemberNumber] = useState("");
  const currentUser = useReactiveVar(__currentUser__);
  const { data: userPrograms, loading: userProgramsLoading } =
    useGetUserProgramsByServiceQuery({
      variables: {
        userId: parseInt(renderDecodedId(currentUser?.id)),
        serviceId: 2,
      },
    });
  const { data, refetch } = useUpgradePaymentListQuery({
    variables: {
      market: market,
      memberNumber: memberNumber,
    },
  });
  const handleSubmit = () => {
    const { memberNumber } = form.getFieldsValue();
    setMemberNumber(memberNumber);
    setShowData(true);
    refetch();
  };
  const handleChange = (value: string) => {
    setMarket(value);
  };
  useEffect(() => {
    setSelectedService(ServiceTypes.CLUB_APP);
    setSelectSubItem("Buy Up Query");
  }, []);
  useEffect(() => {
    if (currentUser?.afUserMarkets && currentUser?.afUserMarkets[0]?.market) {
      if (currentUser?.afUserMarkets.length === 1) {
        setMarket(currentUser?.afUserMarkets[0]?.market);
        form.setFieldValue("market", currentUser?.afUserMarkets[0]?.market);
      } else {
        setMarket("KR");
      }
    }
  }, [currentUser]);
  useEffect(() => {
    if (data?.upgradePaymentList) {
      setDataDisplay(data.upgradePaymentList);
    }
  }, [data]);
  const columns: ColumnsType<BuyUpQueryDataType> = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Member Number",
      dataIndex: "memberNo",
      key: "memberNo",
      width: 100,
    },
    {
      title: "Original Package",
      dataIndex: "originalPackage",
      key: "originalPackage",
    },
    {
      title: "Upgrade Package",
      dataIndex: "upgradePackage",
      key: "upgradePackage",
    },
    {
      title: "Add Beam Projector",
      dataIndex: "addBeamProjector",
      width: 100,
      key: "addBeamProjector",
      render: (v, _) => (v === 1 ? "Yes" : "No"),
    },
    {
      title: "Add Dongle",
      dataIndex: "addDongle",
      width: 100,
      key: "addDongle",
      render: (v, _) => (v === 1 ? "Yes" : "No"),
    },
    {
      title: "Payment Amount",
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      width: 100,
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      width: 100,
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (v, _) => (v ? dayjs(v).format("YYYY-MM-DD") : "N/A"),
    },
    {
      title: "Canceled Date",
      dataIndex: "canceledDate",
      key: "canceledDate",
      render: (v, _) => (v ? dayjs(v).format("YYYY-MM-DD") : "N/A"),
    },
    {
      title: "Change Address",
      dataIndex: "isChangeAddress",
      key: "isChangeAddress",
      width: 100,
      render: (v, _) => (v ? "Yes" : "No"),
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (v, _) => (v ? dayjs(v).format("YYYY-MM-DD") : "N/A"),
    },
  ];

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Buy Up Query"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div id="buyUpQueryWrapper">
      <ContentTitle title="Buy Up Query" />
      <div className="table-container">
        {currentUser?.afUserMarkets?.length &&
          currentUser?.afUserMarkets?.length > 1 && (
            <div className="flex justify-end p-5">
              <div>
                <span>Market:</span>
                <Select
                  onChange={handleChange}
                  defaultValue={market}
                  options={[
                    {
                      value: "TW",
                      label: "TW",
                    },
                    {
                      value: "HK",
                      label: "HK",
                    },
                    {
                      value: "KR",
                      label: "KR",
                    },
                  ]}
                />
              </div>
            </div>
          )}
        <Form form={form} name="buy-up-query-form" className="flex gap-10">
          <Form.Item
            label="Member Number"
            name="memberNumber"
            className="w-3/12"
          >
            <Input />
          </Form.Item>
          <div className="flex">
            <Button
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then(() => {
                    handleSubmit();
                  })
                  .catch((e) => console.log(e));
              }}
            >
              Query
            </Button>
            <Button
              className="ms-2"
              onClick={() => {
                form.resetFields();
              }}
            >
              Clear
            </Button>
          </div>
        </Form>
      </div>
      {showData && (
        <div className="table-container">
          <Table
            columns={columns}
            dataSource={dataDisplay}
            scroll={{ x: "max-content", y: 550 }}
          />
        </div>
      )}
    </div>
  );
};

export default BuyUpQuery;
