import ContentTitle from "../../../components/ui/ContentTitle";
import { useState, useEffect } from "react";
import { Dispatch, SetStateAction } from "react";
import { apiCall } from "../../../utils/apiCall";
import { Calendar, Select, Space, TreeSelect, Tooltip, message } from "antd";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import { useReactiveVar } from "@apollo/client";
import { __currentUser__ } from "../../../graphql/policies";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import dayjs, { Dayjs } from "dayjs";
import {
  monthMapping,
  CalendarDataType,
  EventDataType,
  eventTypeList,
} from "./utils/data";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";
import { ServiceTypes } from "../../../types/types";
import { BranchInfoType } from "../leadsManagement/types";
import fetchAdvisorsData from "../leadsManagement/utils/fetchAdvisorsData";
import { convertToAdvisorTreeSelectOptions } from "../leadsManagement/utils/convertToAdvisorTreeSelectOptions";
import "./Calendar.scss";

interface HeaderRenderProps {
  value: Dayjs; // 當前日曆的日期
  onChange: (date: Dayjs) => void; // 更新日曆日期的方法
}

interface SalesCalendarListProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const SalesCalendar = ({
  setSelectedService,
  setSelectSubItem,
}: SalesCalendarListProps) => {
  const [dataDisplay, setDataDisplay] = useState<EventDataType[]>([]);
  const [advisorsTreeSelectOptions, setAdvisorsTreeSelectOptions] =
    useState<any>([]);
  const [advisors, setAdvisors] = useState<BranchInfoType[]>([]);
  const [selectedAdvisor, setSelectedAdvisor] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const currentUser = useReactiveVar(__currentUser__);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 5,
    },
  });

  const header = {
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
    },
  };

  const currentDate = dayjs(); // 獲取當前時間
  const startDate = currentDate.subtract(1, "year"); // 計算一年前的日期
  const endDate = currentDate; // 當前時間即為結束日期

  const headerRender = ({ value, onChange }: HeaderRenderProps) => {
    const currentYear = value.year();
    const currentMonth = value.month();
    setSelectedMonth(currentMonth + 1 + "");
    setSelectedYear(currentYear + "");

    // 動態計算年份範圍
    const yearOptions = [];
    for (let year = startDate.year(); year <= endDate.year(); year++) {
      yearOptions.push(
        <Select.Option key={year} value={year}>
          {year}
        </Select.Option>
      );
    }

    // 動態計算月份範圍
    const monthOptions = [];
    const isStartYear = currentYear === startDate.year();
    const isEndYear = currentYear === endDate.year();
    const startMonth = isStartYear ? startDate.month() : 0;
    const endMonth = isEndYear ? endDate.month() : 11;

    for (let i = startMonth; i <= endMonth; i++) {
      monthOptions.push(
        <Select.Option key={i} value={i}>
          {monthMapping[i]}
        </Select.Option>
      );
    }

    // 切換年份
    const handleYearChange = (year: number) => {
      let newValue = value.clone().year(year);
      if (year === startDate.year()) {
        newValue = newValue.month(startDate.month()); // 鎖定到範圍的起始月份
      }
      if (year === endDate.year() && newValue.month() > endDate.month()) {
        newValue = newValue.month(endDate.month()); // 鎖定到範圍的結束月份
      }
      onChange(newValue);
    };

    // 切換月份
    const handleMonthChange = (month: number) => {
      const newValue = value.clone().month(month);
      onChange(newValue);
    };

    return (
      <div style={{ padding: 8 }} className="flex justify-between">
        <Space>
          {/* 年份選單 */}
          <Select
            value={currentYear}
            onChange={handleYearChange}
            className="short-select"
          >
            {yearOptions}
          </Select>

          {/* 月份選單 */}
          <Select
            value={currentMonth}
            onChange={handleMonthChange}
            className="short-select"
          >
            {monthOptions}
          </Select>
        </Space>
        <TreeSelect
          placeholder="지사&담당자 선택"
          treeData={advisorsTreeSelectOptions}
          className="long-select"
          onChange={(e) => setSelectedAdvisor(e)}
        />
      </div>
    );
  };

  const formatTime = (time: string): string => {
    const [hour, minute] = time.split(":").map(Number); // 拆分小時和分鐘
    const period = hour >= 12 ? "오후" : "오전"; // 判斷上午或下午
    const formattedHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour; // 轉換為 12 小時制
    return `${period} ${formattedHour}:${minute < 10 ? `0${minute}` : minute}`;
  };

  const dateCellRender = (value: Dayjs) => {
    const formattedDate = value.format("YYYY-MM-DD");
    const events = selectedAdvisor
      ? dataDisplay.filter(
          (item) =>
            item.date === formattedDate &&
            item.data.companyNumber === selectedAdvisor
        )
      : dataDisplay.filter((item) => item.date === formattedDate);
    return (
      <ul>
        {events.map((unit, index) => (
          <Tooltip
            color={
              eventTypeList.find((item) => item.value === unit.data.category)
                ?.color
            }
            title={
              <div className="p-2">
                <p>
                  {
                    eventTypeList.find(
                      (item) => item.value === unit.data.category
                    )?.text
                  }
                </p>
                <p className="mt-2">{formatTime(unit.data.startTime)}</p>
                <p className="mt-2">{unit.data.name}</p>
              </div>
            }
          >
            <li key={index} className="flex items-center">
              <div
                style={{
                  borderRadius: "50%",
                  height: "6px",
                  width: "6px",
                  backgroundColor: eventTypeList.find(
                    (item) => item.value === unit.data.category
                  )?.color,
                  marginRight: "8px",
                }}
              />
              {formatTime(unit.data.startTime) + " " + unit.data.name}
            </li>
          </Tooltip>
        ))}
      </ul>
    );
  };

  const fetchData = async (year: string, month: string) => {
    const data = await apiCall({
      method: "GET",
      header: header,
      endPoint:
        month.length > 1
          ? process.env.REACT_APP_SALES_ADMIN +
            "/events/admin/" +
            year +
            "/" +
            month
          : process.env.REACT_APP_SALES_ADMIN +
            "/events/admin/" +
            year +
            "/0" +
            month,
    });
    if (data) {
      const transformedData = data.flatMap((item: CalendarDataType) =>
        item.data.map((event) => ({
          date: item.date,
          data: event,
        }))
      );
      setDataDisplay(transformedData);
    }
  };

  useEffect(() => {
    fetchData(selectedYear, selectedMonth);
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    setSelectedService(ServiceTypes.SALES_188);
    setSelectSubItem("업무 관리");
  }, []);

  useEffect(() => {
    fetchAdvisorsData(header, setAdvisors);
  }, []);

  useEffect(() => {
    if (advisors.length > 0) {
      setAdvisorsTreeSelectOptions(convertToAdvisorTreeSelectOptions(advisors));
    }
  }, [advisors]);

  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Business Management"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div id="calendarWrapper">
      <ContentTitle title="월간 일정" />
      <div className="content-container">
        <Calendar
          mode="month"
          headerRender={headerRender}
          cellRender={dateCellRender}
        />
      </div>
    </div>
  );
};

export default SalesCalendar;
