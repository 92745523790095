import * as Types from '../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MergeLeadsReplyDataMutationVariables = Types.Exact<{
  retainedLeadsNumber: Types.Scalars['String']['input'];
  mergedLeadsNumber: Types.Scalars['String']['input'];
}>;


export type MergeLeadsReplyDataMutation = { __typename?: 'Mutation', mergeLeadsReplyData: { __typename?: 'ResultResponse', count: number, error?: string | null } };


export const MergeLeadsReplyDataDocument = gql`
    mutation MergeLeadsReplyData($retainedLeadsNumber: String!, $mergedLeadsNumber: String!) {
  mergeLeadsReplyData(
    parameter: {retainedLeadsNumber: $retainedLeadsNumber, mergedLeadsNumber: $mergedLeadsNumber}
  ) {
    count
    error
  }
}
    `;
export type MergeLeadsReplyDataMutationFn = Apollo.MutationFunction<MergeLeadsReplyDataMutation, MergeLeadsReplyDataMutationVariables>;

/**
 * __useMergeLeadsReplyDataMutation__
 *
 * To run a mutation, you first call `useMergeLeadsReplyDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeLeadsReplyDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeLeadsReplyDataMutation, { data, loading, error }] = useMergeLeadsReplyDataMutation({
 *   variables: {
 *      retainedLeadsNumber: // value for 'retainedLeadsNumber'
 *      mergedLeadsNumber: // value for 'mergedLeadsNumber'
 *   },
 * });
 */
export function useMergeLeadsReplyDataMutation(baseOptions?: Apollo.MutationHookOptions<MergeLeadsReplyDataMutation, MergeLeadsReplyDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MergeLeadsReplyDataMutation, MergeLeadsReplyDataMutationVariables>(MergeLeadsReplyDataDocument, options);
      }
export type MergeLeadsReplyDataMutationHookResult = ReturnType<typeof useMergeLeadsReplyDataMutation>;
export type MergeLeadsReplyDataMutationResult = Apollo.MutationResult<MergeLeadsReplyDataMutation>;
export type MergeLeadsReplyDataMutationOptions = Apollo.BaseMutationOptions<MergeLeadsReplyDataMutation, MergeLeadsReplyDataMutationVariables>;