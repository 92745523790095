import { Button, Form, Select, Upload, Input } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import requiredRule from "../../../../utils/formRules/requiredRule";
import normFile from "../../utils/normFile";
import {
  imageValidation,
  lrcValidation,
  mp3Validation,
  mp4Validation,
} from "../utils/uploadValidation";
import { productCategoryOptions } from "../constants";

function FormItemCategory({
  setIsLyricRequired,
}: {
  setIsLyricRequired?: any;
}) {
  return (
    <Form.Item name="productCategoryId" label="Category" rules={[requiredRule]}>
      <Select
        options={productCategoryOptions()}
        placeholder="- Select -"
        onChange={(e: any) => {
          setIsLyricRequired && setIsLyricRequired(e !== 17);
        }}
      />
    </Form.Item>
  );
}

function FormItemUploadMp3() {
  return (
    <Form.Item
      name="audioFile"
      label="Song file"
      valuePropName="fileList"
      getValueFromEvent={normFile}
      rules={[requiredRule]}
      extra="Format: MP3"
    >
      <Upload
        action="https://httpbin.org/post"
        beforeUpload={mp3Validation}
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>Click to upload</Button>
      </Upload>
    </Form.Item>
  );
}

function FormItemUploadLyric({ required = true }: { required?: boolean }) {
  return (
    <Form.Item
      name="lyricsFile"
      label="Lyrics"
      valuePropName="fileList"
      getValueFromEvent={normFile}
      rules={required ? [requiredRule] : []}
      extra="Format: lrc"
    >
      <Upload
        action="https://httpbin.org/post"
        beforeUpload={lrcValidation}
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>Click to upload</Button>
      </Upload>
    </Form.Item>
  );
}

function FormItemVideoThumbnail() {
  return (
    <Form.Item
      name="videoThumbnailFile"
      label="Video thumbnail"
      valuePropName="fileList"
      getValueFromEvent={normFile}
      rules={[requiredRule]}
      extra="Format: JPEG, JPG, PNG"
    >
      <Upload
        action="https://httpbin.org/post"
        beforeUpload={imageValidation}
        maxCount={1}
        listType="picture"
      >
        <Button icon={<UploadOutlined />}>Click to upload</Button>
      </Upload>
    </Form.Item>
  );
}

function FormItemVideo({
  name,
  label,
  extra,
}: {
  name: string;
  label: string;
  extra: string;
}) {
  return (
    <Form.Item name={name} label={label} rules={[requiredRule]} extra={extra}>
      <Input />
    </Form.Item>
  );
}

export {
  FormItemCategory,
  FormItemUploadMp3,
  FormItemUploadLyric,
  FormItemVideoThumbnail,
  FormItemVideo,
};
