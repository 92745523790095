import ContentTitle from "../../../components/ui/ContentTitle";
import { Dispatch, SetStateAction } from "react";
import { ServiceTypes } from "../../../types/types";
import { useReactiveVar } from "@apollo/client";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { Form, Button, Input, message } from "antd";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import { useState, useEffect } from "react";
import "./UpdateSourceCode.scss";
import { __currentUser__ } from "../../../graphql/policies";
import requiredRule from "../../../utils/formRules/requiredRule";
import { useLeadsReplySourceInformationQuery } from "../../../graphql/operations/mkt/lead-reply-source-information";
import { useUpdateLeadsSourceMutation } from "../../../graphql/operations/mkt/update-lead-source";

interface DataType {
  assignDate: string;
  assignEndDate: string;
  assignId: number;
  father: string;
  keyinDate: string;
  leadsNumber: string;
  mother: string;
  promoter: string;
  salesCode: string;
  sourceCode: string;
  sourceId: number;
  __typename?: string | undefined;
}

interface UpdateSourceCodeProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const UpdateSourceCode = ({
  setSelectedService,
  setSelectSubItem,
}: UpdateSourceCodeProps) => {
  const [form] = Form.useForm();
  const [leadNo, setLeadNo] = useState("");
  const [code, setCode] = useState("");
  const [shouldQuery, setShouldQuery] = useState(false);
  const [displayData, setDisplayData] = useState<DataType | null>(null);
  const currentUser = useReactiveVar(__currentUser__);
  const { data: userPrograms, loading: userProgramsLoading } =
    useGetUserProgramsByServiceQuery({
      variables: {
        userId: parseInt(renderDecodedId(currentUser?.id)),
        serviceId: 4,
      },
    });
  const handleUpdate = () => {
    form
      .validateFields()
      .then(() => {
        const { newCode } = form.getFieldsValue();
        if (displayData) {
          updateLeadsSource({
            variables: {
              leadsNumber: displayData.leadsNumber,
              sourceId: displayData.sourceId,
              assignId: displayData.assignId,
              newSourceCode: newCode,
            },
          }).then((data) => {
            if (
              data.data?.updateLeadsReplySourceInformation.count === 1 &&
              !data.data.updateLeadsReplySourceInformation.error
            ) {
              message.success(
                renderAntDMessageConfig("Source code successfully updated")
              );
              window.location.reload();
            } else if (data.data?.updateLeadsReplySourceInformation.error) {
              message.error(
                renderAntDMessageConfig(
                  "Something went wrong, please try again"
                )
              );
            }
          });
        }
      })
      .catch((e) => console.log(e));
  };
  const { data, refetch } = useLeadsReplySourceInformationQuery({
    variables: {
      leadsNumber: leadNo,
      sourceCode: code,
    },
    skip: !shouldQuery,
  });
  const [updateLeadsSource] = useUpdateLeadsSourceMutation();
  const handleSubmit = () => {
    setDisplayData(null);
    form
      .validateFields()
      .then(() => {
        const { leadNo, code } = form.getFieldsValue();
        setLeadNo(leadNo);
        setCode(code);
        form.setFieldValue("newCode", "");
        setShouldQuery(true);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    setSelectedService(ServiceTypes.MKT);
    setSelectSubItem("Update Source Code");
  }, []);

  useEffect(() => {
    if (
      data?.leadsReplySourceInformation.data &&
      !data?.leadsReplySourceInformation.error
    ) {
      setDisplayData(data.leadsReplySourceInformation.data);
    } else if (data?.leadsReplySourceInformation.error) {
      const errorMsg = data.leadsReplySourceInformation.error;
      message.error(renderAntDMessageConfig(errorMsg));
      setDisplayData(null);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [leadNo, code]);

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Update Source Code"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div id="updateSourceCodeWrapper">
      <ContentTitle title="Update Source Code" />
      <div className="table-container">
        <Form name="update-source-code-form" form={form} className="form">
          <div className="flex">
            <Form.Item
              name="leadNo"
              rules={[requiredRule]}
              label="Lead number"
              className="item"
            >
              <Input className="w-full" />
            </Form.Item>
            <Form.Item
              name="code"
              rules={[requiredRule]}
              label="Source code"
              className="ms-5 item"
            >
              <Input className="w-full" />
            </Form.Item>
            <Button
              type="primary"
              className="ms-5"
              onClick={handleSubmit}
              //   loading={isLoading}
            >
              Query
            </Button>
          </div>
          {displayData && (
            <div className="mt-5 form">
              <p className="font-bold">Leads Customer Information</p>
              <div className="flex mt-5">
                <div className="w-1/4 flex">
                  <p>Lead No:</p>
                  <p className="value ms-2">{displayData.leadsNumber}</p>
                </div>
                <div className="w-1/4 flex">
                  <p>Mother:</p>
                  <p className="value ms-2">{displayData.mother}</p>
                </div>
                <div className="w-1/4 flex">
                  <p>Father:</p>
                  <p className="value ms-2">{displayData.father}</p>
                </div>
              </div>
              <p className="mt-5 font-bold">Leads Source Information</p>
              <div className="flex mt-5">
                <div className="w-1/4 flex">
                  <p>Source ID:</p>
                  <p className="value ms-2">{displayData.sourceId}</p>
                </div>
                <div className="w-1/4 flex">
                  <p>Key In Date:</p>
                  <p className="value ms-2">{displayData.keyinDate}</p>
                </div>
                <div className="w-1/4 flex">
                  <p>Source Code:</p>
                  <p className="value ms-2">{displayData.sourceCode}</p>
                </div>
                <div className="w-1/4 flex">
                  <p>Promoter:</p>
                  <p className="value ms-2">{displayData.promoter}</p>
                </div>
              </div>
              <p className="mt-5 font-bold">Leads Assign Information</p>
              <div className="flex mt-5">
                <div className="w-1/4 flex">
                  <p>Assign ID:</p>
                  <p className="value ms-2">{displayData.assignId}</p>
                </div>
                <div className="w-1/4 flex">
                  <p>Assign Date:</p>
                  <p className="value ms-2">{displayData.assignDate}</p>
                </div>
                <div className="w-1/4 flex">
                  <p>Assign End Date:</p>
                  <p className="value ms-2">{displayData.assignEndDate}</p>
                </div>
                <div className="w-1/4 flex">
                  <p>Sales Code:</p>
                  <p className="value ms-2">{displayData.salesCode}</p>
                </div>
              </div>
            </div>
          )}
          {displayData && (
            <div className="flex mt-5">
              <Form.Item
                name="newCode"
                label="New Source Code"
                className="item"
                rules={[requiredRule]}
              >
                <Input className="w-full" />
              </Form.Item>
              <Button type="primary" className="ms-5" onClick={handleUpdate}>
                Update
              </Button>
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default UpdateSourceCode;
