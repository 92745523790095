import { Modal } from "antd";

export default function VideoModel<
  T extends {
    videoTitle: string;
    videoUrl: string;
    videoFileName: string;
  }
>({
  modalData,
  setModalData,
  videoRef,
}: {
  modalData: T | undefined;
  setModalData: React.Dispatch<React.SetStateAction<T | undefined>>;
  videoRef: React.RefObject<HTMLIFrameElement>;
}) {
  return (
    <Modal
      title={modalData?.videoTitle}
      open={Boolean(modalData)}
      onCancel={() => setModalData(undefined)}
      footer={null}
    >
      <iframe
        className="w-full"
        ref={videoRef}
        src={
          modalData?.videoFileName
            ? `https://player.vimeo.com/video/${modalData.videoFileName}`
            : ""
        }
      />
    </Modal>
  );
}
