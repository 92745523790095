import * as Types from '../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateLeadsSourceMutationVariables = Types.Exact<{
  leadsNumber: Types.Scalars['String']['input'];
  newSourceCode: Types.Scalars['String']['input'];
  sourceId: Types.Scalars['Int']['input'];
  assignId: Types.Scalars['Int']['input'];
}>;


export type UpdateLeadsSourceMutation = { __typename?: 'Mutation', updateLeadsReplySourceInformation: { __typename?: 'ResultResponse', count: number, error?: string | null } };


export const UpdateLeadsSourceDocument = gql`
    mutation UpdateLeadsSource($leadsNumber: String!, $newSourceCode: String!, $sourceId: Int!, $assignId: Int!) {
  updateLeadsReplySourceInformation(
    parameter: {leadsNumber: $leadsNumber, newSourceCode: $newSourceCode, sourceId: $sourceId, assignId: $assignId}
  ) {
    count
    error
  }
}
    `;
export type UpdateLeadsSourceMutationFn = Apollo.MutationFunction<UpdateLeadsSourceMutation, UpdateLeadsSourceMutationVariables>;

/**
 * __useUpdateLeadsSourceMutation__
 *
 * To run a mutation, you first call `useUpdateLeadsSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeadsSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeadsSourceMutation, { data, loading, error }] = useUpdateLeadsSourceMutation({
 *   variables: {
 *      leadsNumber: // value for 'leadsNumber'
 *      newSourceCode: // value for 'newSourceCode'
 *      sourceId: // value for 'sourceId'
 *      assignId: // value for 'assignId'
 *   },
 * });
 */
export function useUpdateLeadsSourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLeadsSourceMutation, UpdateLeadsSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLeadsSourceMutation, UpdateLeadsSourceMutationVariables>(UpdateLeadsSourceDocument, options);
      }
export type UpdateLeadsSourceMutationHookResult = ReturnType<typeof useUpdateLeadsSourceMutation>;
export type UpdateLeadsSourceMutationResult = Apollo.MutationResult<UpdateLeadsSourceMutation>;
export type UpdateLeadsSourceMutationOptions = Apollo.BaseMutationOptions<UpdateLeadsSourceMutation, UpdateLeadsSourceMutationVariables>;