import { QueryFormValues } from "../types";
import dayjs from "dayjs";
export default function convertToQueryString(values: QueryFormValues) {
  const queryParams = Object.entries(values).filter(
    ([_, value]) => value != null && value !== ""
  );

  let queryString = "";

  queryParams.forEach((v, i) => {
    if (i === 0) {
      queryString += "?";
    }
    switch (v[0]) {
      case "type":
        const types = v[1].join();
        queryString += `leadTypes=${types}`;
        break;
      case "cycle":
        queryString += `leadCycle=${v[1]}`;
        break;
      case "assignee":
        if (v[1] !== "all") queryString += `assignee=${v[1]}`;
        break;
      case "log":
        queryString += `callLogStatus=${v[1]}`;
        break;
      case "keep":
        queryString += `isKeep=${v[1]}`;
        break;
      case "duplicate":
        queryString += `isDuplicate=${v[1]}`;
        break;
      case "refund":
        queryString += `refundStatus=${v[1]}`;
        break;
      case "deletion":
        queryString += `deletionStatus=${v[1]}`;
        break;
      case "distribution":
        const DStartDate = dayjs(v[1][0]).format("YYYY-MM-DD");
        const DEndDate = dayjs(v[1][1]).format("YYYY-MM-DD");
        queryString += `distributionDate=${DStartDate}~${DEndDate}`;
        break;
      case "expiry":
        const EStartDate = dayjs(v[1][0]).format("YYYY-MM-DD");
        const EEndDate = dayjs(v[1][1]).format("YYYY-MM-DD");
        queryString += `expiryDate=${EStartDate}~${EEndDate}`;
        break;
      case "creation":
        const CStartDate = dayjs(v[1][0]).format("YYYY-MM-DD");
        const CEndDate = dayjs(v[1][1]).format("YYYY-MM-DD");
        queryString += `creationDate=${CStartDate}~${CEndDate}`;
        break;
      case "number":
        queryString += `leadno=${v[1]}`;
        break;
      case "prospect":
        queryString += `name=${v[1]}`;
        break;
      case "phone":
        queryString += `phoneNumber=${v[1]}`;
        break;
      case "child":
        queryString += `childName=${v[1]}`;
        break;
      case "address":
        queryString += `address=${v[1]}`;
        break;
    }
    if (i < queryParams.length - 1) {
      queryString += "&";
    }
  });

  return queryString;
}
