export const leadTypeOptions = [
  {
    value: "CL",
    title: "CL",
    children: [
      { value: "U", title: "U" },
      { value: "NC", title: "NC" },
      { value: "P", title: "P" },
    ],
  },
  {
    value: "DL",
    title: "DL",
    children: [
      { value: "LD", title: "LD" },
      { value: "GD", title: "GD" },
      { value: "BD", title: "BD" },
      { value: "PD", title: "PD" },
    ],
  },
];

export const cycleOptions = [
  { label: "Fresh", value: "fresh" },
  { label: "Recycle", value: "recycle" },
  { label: "Old", value: "old" },
  { label: "Sleep", value: "sleep" },
];

export const logStatusOptions = [
  { value: "pending", label: "미진행", color: "#D9D9D9" },
  { value: "appo", label: "아포", color: "#C7894B" },
  { value: "unappo", label: "아포취소", color: "#FED241" },
  { value: "demo", label: "데모", color: "#00B140" },
  { value: "contract", label: "계약완료", color: "#F04950" },
];

export const keepOptions = [
  { label: "해당없음", value: "false" },
  { label: "Keep", value: "true" },
];

export const duplicateOptions = [
  { label: "해당없음", value: "false" },
  { label: "중복", value: "true" },
];

export const refundOptions = [
  { label: "해당없음", value: "" },
  { label: "신청완료", value: "refunded" },
  { label: "리펀드완료", value: "requested" },
];

export const deleteOptions = [
  { label: "해당없음", value: "" },
  { label: "신청완료", value: "deleted" },
  { label: "삭제완료", value: "requested" },
];

export const refundReasonList = [
  { label: "Available phone call BUT didn't request for IP", value: 1 },
  { label: "Unavailable phone call", value: 2 },
  { label: "Foreigner", value: 3 },
  { label: "Request from same family", value: 4 },
  { label: "IP request from non-parents", value: 5 },
  { label: "Returning customer", value: 6 },
  { label: "Refuse approach", value: 7 },
];

export const refundStatusList = [
  {
    label: "리펀드 요청",
    value: "requested",
  },
  {
    label: "리펀드 처리완료",
    value: "completed",
  },
  {
    label: "리펀드 요청 철회",
    value: "canceled",
  },
];

export const deleteStatusList = [
  {
    label: "삭제 요청",
    value: "requested",
  },
  {
    label: "삭제 처리완료",
    value: "completed",
  },
  {
    label: "삭제 요청 철회",
    value: "canceled",
  },
];
