import * as Types from '../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LeadsReplySourceInformationQueryVariables = Types.Exact<{
  leadsNumber: Types.Scalars['String']['input'];
  sourceCode: Types.Scalars['String']['input'];
}>;


export type LeadsReplySourceInformationQuery = { __typename?: 'Query', leadsReplySourceInformation: { __typename?: 'ApiResponseOfGetLeadsReplySourceInformationResponse', error?: string | null, data?: { __typename?: 'GetLeadsReplySourceInformationResponse', leadsNumber: string, father: string, mother: string, sourceId: number, keyinDate: string, sourceCode: string, promoter: string, assignId: number, assignDate: string, assignEndDate: string, salesCode: string } | null } };


export const LeadsReplySourceInformationDocument = gql`
    query LeadsReplySourceInformation($leadsNumber: String!, $sourceCode: String!) {
  leadsReplySourceInformation(
    parameter: {leadsNumber: $leadsNumber, sourceCode: $sourceCode}
  ) {
    error
    data {
      leadsNumber
      father
      mother
      sourceId
      keyinDate
      sourceCode
      promoter
      assignId
      assignDate
      assignEndDate
      salesCode
    }
  }
}
    `;

/**
 * __useLeadsReplySourceInformationQuery__
 *
 * To run a query within a React component, call `useLeadsReplySourceInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadsReplySourceInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadsReplySourceInformationQuery({
 *   variables: {
 *      leadsNumber: // value for 'leadsNumber'
 *      sourceCode: // value for 'sourceCode'
 *   },
 * });
 */
export function useLeadsReplySourceInformationQuery(baseOptions: Apollo.QueryHookOptions<LeadsReplySourceInformationQuery, LeadsReplySourceInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeadsReplySourceInformationQuery, LeadsReplySourceInformationQueryVariables>(LeadsReplySourceInformationDocument, options);
      }
export function useLeadsReplySourceInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeadsReplySourceInformationQuery, LeadsReplySourceInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeadsReplySourceInformationQuery, LeadsReplySourceInformationQueryVariables>(LeadsReplySourceInformationDocument, options);
        }
export type LeadsReplySourceInformationQueryHookResult = ReturnType<typeof useLeadsReplySourceInformationQuery>;
export type LeadsReplySourceInformationLazyQueryHookResult = ReturnType<typeof useLeadsReplySourceInformationLazyQuery>;
export type LeadsReplySourceInformationQueryResult = Apollo.QueryResult<LeadsReplySourceInformationQuery, LeadsReplySourceInformationQueryVariables>;