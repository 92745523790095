import ContentTitle from "../../../components/ui/ContentTitle";
import requiredRule from "../../../utils/formRules/requiredRule";
import { Dispatch, SetStateAction } from "react";
import { ServiceTypes } from "../../../types/types";
import { useReactiveVar } from "@apollo/client";
import { useState, useEffect } from "react";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import { useMergeLeadsReplyDataMutation } from "../../../graphql/operations/mkt/merge-leads";
import { __currentUser__ } from "../../../graphql/policies";
import { Form, Button, Input, message } from "antd";
import "./MergeLeads.scss";

interface MergeLeadsProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const MergeLeads = ({
  setSelectedService,
  setSelectSubItem,
}: MergeLeadsProps) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useReactiveVar(__currentUser__);
  const { data: userPrograms, loading: userProgramsLoading } =
    useGetUserProgramsByServiceQuery({
      variables: {
        userId: parseInt(renderDecodedId(currentUser?.id)),
        serviceId: 4,
      },
    });
  const [mergeLeads] = useMergeLeadsReplyDataMutation();

  useEffect(() => {
    setSelectedService(ServiceTypes.MKT);
    setSelectSubItem("Merge Leads");
  }, []);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(() => {
        setIsLoading(true);
        const { retained, merged } = form.getFieldsValue();
        mergeLeads({
          variables: {
            retainedLeadsNumber: retained,
            mergedLeadsNumber: merged,
          },
        })
          .then((data) => {
            setIsLoading(false);
            if (
              data.data?.mergeLeadsReplyData &&
              data.data?.mergeLeadsReplyData.error
            ) {
              message.error(
                renderAntDMessageConfig(data.data.mergeLeadsReplyData.error)
              );
            } else {
              message.success(renderAntDMessageConfig("Successfully merged!"));
            }
          })
          .catch((e: any) => {
            setIsLoading(false);
            console.log(e);
          });
      })
      .catch((e) => console.log(e));
  };

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Merge Leads"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div id="mergeLeadsWrapper">
      <ContentTitle title="Merge Leads" />
      <div className="table-container">
        <Form form={form} name="merge-leads-form" className="form">
          <div className="flex">
            <Form.Item
              name="retained"
              rules={[requiredRule]}
              label="Retained Lead Number"
              className="item"
            >
              <Input className="w-full" />
            </Form.Item>
            <Form.Item
              name="merged"
              rules={[requiredRule]}
              label="Merged Lead Number"
              className="ms-5 item"
            >
              <Input className="w-full" />
            </Form.Item>
            <Button
              type="primary"
              className="ms-5"
              onClick={handleSubmit}
              loading={isLoading}
            >
              Merge
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default MergeLeads;
