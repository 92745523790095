import ContentTitle from "../../../components/ui/ContentTitle";
import requiredRule from "../../../utils/formRules/requiredRule";
import { Dispatch, SetStateAction } from "react";
import { ServiceTypes } from "../../../types/types";
import { useReactiveVar } from "@apollo/client";
import { useState, useEffect } from "react";
import { apiCall } from "../../../utils/apiCall";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import fileDownload from "js-file-download";
import {
  __currentUser__,
  __jwtToken__,
  __projectKey__,
} from "../../../graphql/policies";
import dayjs from "dayjs";
import { Form, Button, DatePicker } from "antd";
import "./GetWinner.scss";

interface GetWinnerProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const GetWinner = ({
  setSelectedService,
  setSelectSubItem,
}: GetWinnerProps) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useReactiveVar(__currentUser__);
  const jwtToken = useReactiveVar(__jwtToken__);
  const projectKey = useReactiveVar(__projectKey__);
  const { data: userPrograms, loading: userProgramsLoading } =
    useGetUserProgramsByServiceQuery({
      variables: {
        userId: parseInt(renderDecodedId(currentUser?.id)),
        serviceId: 4,
      },
    });

  useEffect(() => {
    setSelectedService(ServiceTypes.MKT);
    setSelectSubItem("Get Winner");
  }, []);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async () => {
        setIsLoading(true);
        const { start, end } = form.getFieldsValue();
        const header = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "X-Project-Id": "AF",
            "X-Authorization-Provider": "Anonymous",
            "X-Project-Key": projectKey,
          },
          responseType: "blob",
        };
        const data = await apiCall({
          method: "GET",
          header: header,
          endPoint:
            process.env.REACT_APP_GET_WINNER +
            dayjs(start).format("YYYY-MM-DD") +
            "/" +
            dayjs(end).format("YYYY-MM-DD"),
        });
        fileDownload(data, "winner.xlsx");
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  };

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Get Winner"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div id="getWinnerWrapper">
      <ContentTitle title="Get P Table Survey Winner" />
      <div className="table-container">
        <Form form={form} name="get-winner-form" className="form">
          <div className="flex">
            <Form.Item
              name="start"
              rules={[requiredRule]}
              label="Start Date"
              className="item"
            >
              <DatePicker className="w-full" />
            </Form.Item>
            <Form.Item
              name="end"
              rules={[requiredRule]}
              label="End Date"
              className="ms-5 item"
            >
              <DatePicker className="w-full" />
            </Form.Item>
            <Button
              type="primary"
              className="ms-5"
              onClick={handleSubmit}
              loading={isLoading}
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default GetWinner;
