export enum ButtonTypes {
  PRIMARY = "Primary",
  DISABLED = "Disabled",
  SECONDARY = "Secondary",
  NONE = "None",
}

export enum RegionTypes {
  TAIWAN = "TW",
  HONG_KONG = "HK",
  KOREA = "KR",
}

export enum NormalTagTypes {
  EVENT = "Event",
  OTHERS = "Others",
  WARRANTY_SERVICE = "Warranty",
  SCHEDULED = "Scheduled",
  GO_GO_LIVE = "Go, Go, Live!",
  TELEPHONE_ENGLISH = "Telephone English",
  CAP = "CAP",
  CLUB_NEWS = "Club News",
  MEMBERSHIP = "Membership",
  GRADUATION_APPLY = "Graduation Apply",
  WARRANTY = "Warranty",
  CS_ONE_ONE_BOARD = "CS / 1:1 board",
  DELIVERY = "Delivery",
  SENT = "Sent",
  CHRISTMAS = "Christmas",
  HALLOWEEN = "Halloween",
  NEW_YEAR = "New Year",
  NEW = "New",
  ADMIN = "Admin",
  CLUB_APP = "Club App",
  CLUB_SERVICE = "Club Service",
  SALES_188 = "Sales",
  MKT = "MKT",
  TECHPRESSO = "Techpresso",
  CHECK_IN_APP = "Check-in",
  PENDING = "Pending",
  SUCCESS = "Success",
  TRIAL_MODE = "Trial Mode",
}

export enum ActionTagTypes {
  CREATE = "Create",
  UPDATE = "Update",
  READ = "Read",
  DELETE = "Delete",
}

export enum InputTextLabelPositionTypes {
  UP = "Up",
  LEFT = "Left",
  NONE = "None",
}

export enum IconButtonTypes {
  SAVE = "Save",
  CANCEL = "Cancel",
}

export enum ThemeTypes {
  LIGHT = "Light",
  DARK = "Dark",
  TRIAL_MODE = "TrialMode",
}

export enum ServiceTypes {
  CLUB_APP = "Club App",
  CLUB_SERVICE = "Club Service",
  SALES_188 = "Sales 188",
  DEMO = "WFE Demo",
  MKT = "Marketing Service",
  TECHPRESSO = "Techpresso",
  CHECK_IN = "Check-in App",
  TRIAL_MODE = "Trial Mode",
}

export enum ServiceCategoryTypes {
  DASHBOARD = "Dashboard",
  IMAGE = "Image",
  TEXT = "Text",
  CONTENT = "Content",
  OTHERS = "Others",
  ADMIN_SETTINGS = "Settings",
  DESIGN = "Design",
  GRADUATION = "Graduation",
  TRIAL_MODE_PUSH_NOTIFICATION = "Push Notification",
  CLUB_APP_PUSH_NOTIFICATION = "Push Notification",
  SALES_188_PUSH_NOTIFICATION = "Push Notification",
  SALES_188_RELEASE_VERSION = "Release Version",
  SALES_188_LEAD_MANAGEMENT = "리드 관리",
  SALES_188_BUSINESS_MANAGEMENT = "업무 관리",
  SALES_188_SYSTEM_MANAGEMENT = "시스템 관리",
  CLUB_APP_FACE_CALL = "Face Call",
  CLUB_APP_GO_GO_LIVE = "Go, Go Live!",
  CLUB_APP_IMPORTANT_NOTICE = "Important Notice",
  CLUB_APP_RELEASE_VERSION = "Release Version",
  CLUB_APP_BUY_UP_QUERY = "Buy Up Query",
  DATA_ANALYSIS = "Data Analysis",
  VIDEO_MANAGEMENT = "Video management",
}

export type MenuSubItemType = {
  id: number;
  subItemName: string;
  routeName: string;
};

export enum ServiceRouteTypes {
  CLUB_APP = "club-app",
  CLUB_SERVICE = "club-service",
  SALES_188 = "sales188",
  DEMO = "demo-app",
  MKT = "mkt",
  TECHPRESSO = "techpresso",
  CHECK_IN = "check-in-app",
  TRIAL_MODE = "trial-mode",
}

export enum ServiceCategoryRouteTypes {
  DATA_ANALYSIS = "Data Analysis",
  DASHBOARD = "dashboard",
  IMAGE = "image",
  TEXT = "text",
  CONTENT = "content",
  OTHERS = "others",
  ADMIN_SETTINGS = "admin-settings",
  DESIGN = "design",
  GRADUATION = "graduation",
  TRIAL_MODE_PUSH_NOTIFICATION = "push-notification",
  CLUB_APP_PUSH_NOTIFICATION = "push-notification",
  SALES_188_PUSH_NOTIFICATION = "push-notification",
  SALES_188_RELEASE_VERSION = "release-version",
  SALES_188_LEAD_MANAGEMENT = "lead-management",
  SALES_188_BUSINESS_MANAGEMENT = "business-management",
  SALES_188_SYSTEM_MANAGEMENT = "system-management",
  CLUB_APP_FACE_CALL = "face-call",
  CLUB_APP_GO_GO_LIVE = "go-go-live",
  CLUB_APP_IMPORTANT_NOTICE = "important-notice",
  CLUB_APP_RELEASE_VERSION = "release-version",
  CLUB_APP_BUY_UP_QUERY = "buy-up-query",
  VIDEO_MANAGEMENT = "video-management",
}

export enum ClubAppNotificationTypes {
  MANUAL = "Manual",
  REGULAR = "Regular",
}

export enum NotificationServiceTypes {
  CLUB_NEWS = "Club News",
  MEMBERSHIP = "Membership",
  EVENT = "Event",
  TELEPHONE_ENGLISH = "Telephone English",
  CAP = "CAP",
  GRADUATION_APPLY = "Graduation Apply",
  GO_GO_LIVE = "Go, Go, Live!",
  WARRANTY = "Warranty",
  CS_ONE_ONE_BOARD = "CS / 1:1 board",
  DELIVERY = "Delivery",
  SCHEDULED = "Scheduled",
}

export enum NotificationTypes {
  SPECIFIC_EVENT = "Specific event",
  PROMOTE = "Promote",
  SELECTED_MEMBERS = "Selected member(s)",
}

export enum NotificationActionTypes {
  NONE = "None",
  APP_SCREEN = "App screen",
  APP_EMBED_URL = "Embed URL",
  OPEN_BROWSER = "Open browser",
}

export enum SmsStatusTypes {
  DO_NOT_SEND = "Do not send",
  SEND_IF_NO_APP = "Send SMS if member is not using the app",
  SEND = "Send SMS",
}
export interface EventSessionType {
  id: number;
  title: string;
  date: string;
  time: string;
  city: string;
}

export enum ClubNewsTargetTypes {
  ALL_MEMBERS = "All members",
  SELECTED_MEMBERS = "Selected member(s)",
}

export enum FaceCallVideosQueryTypes {
  ALL = "All",
  PUBLISHED = "Published",
  UNPUBLISHED = "Unpublished",
  IN_USE = "In use",
  NOT_IN_USE = "Not in use",
  UPCOMING = "Upcoming",
  THEME = "Theme",
}

export enum FaceCallVideoThemeTypes {
  CHRISTMAS = "Christmas",
  HALLOWEEN = "Halloween",
  NEW_YEAR = "New Year",
  NEW = "New",
  NONE = "None",
}

export enum FaceCallPerformerTypes {
  PERFORMER = "Performer",
  ZIPPY_FAMILY = "Zippy and His Friends",
}

export type AfUserMarketsType = Array<{
  __typename?: "WF_AdminFirst_AfUserMarket";
  market?: string | null;
} | null> | null;

export interface CurrentUserType {
  __typename?: "WF_AdminFirst_AfUser";
  id: string;
  userName?: string | null;
  userEmail?: string | null;
  isAdministrator?: boolean | null;
  avatarUrl?: string | null;
  afUserMarkets?: AfUserMarketsType;
  afUserServices?: Array<{
    __typename?: "WF_AdminFirst_AfUserService";
    afServiceId?: number | null;
  } | null> | null;
}

export type MarketType = "HK" | "KR" | "TW";
