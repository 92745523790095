import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Select,
  Table,
  message,
} from "antd";
import { apiCall } from "../../../utils/apiCall";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import requiredRule from "../../../utils/formRules/requiredRule";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import DrawerFooter from "../../../components/layout/drawer/DrawerFooter";
import { ColumnsType } from "antd/es/table";
import { ProspectInformationType, ChildrenInfoType } from "./types";
import "./leadDetail.scss";
import TextArea from "antd/es/input/TextArea";

interface ProspectInfoDrawerProps {
  open: boolean;
  setOpenedDrawer: any;
  info: ProspectInformationType | undefined;
  refetch: any;
  leadNo: string | undefined;
}

declare global {
  interface Window {
    daum: any;
  }
}

const ProspectInfoDrawer = ({
  open,
  setOpenedDrawer,
  info,
  refetch,
  leadNo,
}: ProspectInfoDrawerProps) => {
  const [form] = Form.useForm();
  const modalRef = useRef<HTMLDivElement>(null);
  const [showAddressWindow, setShowAddressWindow] = useState(false);
  const [childInfoMissing, setChildInfoMissing] = useState(false);
  const [isPregnant, setIsPregnant] = useState(false);
  const [dueDate, setDueDate] = useState<string | null>("");
  const [childInfo, setChildInfo] = useState<ChildrenInfoType[]>([]);

  const columns: ColumnsType<ChildrenInfoType> = [
    {
      title: "",
      render: (_, __, index) => (index !== 3 ? index + 1 : ""),
    },
    {
      title: "자녀이름",
      dataIndex: "name",
      key: "name",
      render: (name, _, index) => {
        return index !== 3 ? (
          <Input
            defaultValue={name}
            placeholder="자녀이름"
            onChange={(e) => {
              const replica = JSON.parse(JSON.stringify(childInfo));
              replica[index].name = e.target.value;
              setChildInfo(replica);
            }}
          />
        ) : (
          <Checkbox
            defaultChecked={isPregnant}
            onChange={(e) => {
              setIsPregnant(e.target.checked);
            }}
          >
            현재 임신중입니다.
          </Checkbox>
        );
      },
    },
    {
      title: "자녀생일",
      dataIndex: "birthday",
      key: "birthday",
      render: (day, _, index) => {
        return (
          <DatePicker
            placeholder="생년월일"
            defaultValue={
              index !== 3
                ? day
                  ? dayjs(day)
                  : undefined
                : dueDate
                ? dayjs(dueDate)
                : undefined
            }
            onChange={(e) => {
              if (index !== 3) {
                const replica = JSON.parse(JSON.stringify(childInfo));
                replica[index].birthday = dayjs(e).format("YYYY-MM-DD");
                setChildInfo(replica);
              } else {
                setDueDate(dayjs(e).format("YYYY-MM-DD"));
              }
            }}
          />
        );
      },
    },
  ];

  const handleSubmit = async () => {
    const header = {
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      },
    };
    const {
      customerName,
      phone,
      email,
      relation,
      zip,
      address1,
      address2,
      memo,
    } = form.getFieldsValue();
    const payload = {
      name: customerName,
      mobileNumber: phone,
      email: email,
      zipCode: zip,
      address1: address1,
      address2: address2,
      parentRelation: relation,
      isPregnant: isPregnant,
      dueDate: dueDate,
      childName1: childInfo[0].name,
      childBirthDate1: childInfo[0].birthday
        ? dayjs(childInfo[0].birthday).format("YYYY-MM-DD")
        : "",
      childName2: childInfo[1].name,
      childBirthDate2: childInfo[1].birthday
        ? dayjs(childInfo[1].birthday).format("YYYY-MM-DD")
        : "",
      childName3: childInfo[2].name,
      childBirthDate3: childInfo[2].birthday
        ? dayjs(childInfo[2].birthday).format("YYYY-MM-DD")
        : "",
      memo: memo,
    };
    await apiCall({
      method: "PATCH",
      header: header,
      endPoint:
        process.env.REACT_APP_SALES_ADMIN +
        "/leads/admin/" +
        leadNo +
        "/prospect",
      payload: payload,
    });
    message.success(
      renderAntDMessageConfig("고객정보 변경 사항이 저장되었습니다.")
    );
    refetch(leadNo);
    setOpenedDrawer("");
    form.resetFields();
    setShowAddressWindow(false);
    setChildInfo([]);
    setIsPregnant(false);
    setDueDate("");
  };

  useEffect(() => {
    if (info?.children) {
      setChildInfo([
        ...info.children,
        ...Array(Math.max(0, 4 - info.children.length)).fill({
          name: "",
          birthday: "",
        }),
      ]);
    }
  }, [info, open]);

  useEffect(() => {
    if (childInfo.length > 0) {
      if (
        !(
          (isPregnant && dueDate) ||
          (childInfo[0].birthday && childInfo[0].name)
        )
      ) {
        setChildInfoMissing(true);
      } else {
        setChildInfoMissing(false);
      }
    }
  }, [isPregnant, dueDate, childInfo]);

  useEffect(() => {
    const loadDaumPostcode = () => {
      new window.daum.Postcode({
        oncomplete: function (data: any) {
          form.setFieldsValue({
            zip: data?.zonecode,
            address1: data?.address,
          });
          setShowAddressWindow(false);
        },
        width: "100%",
        height: "100%",
      }).embed(modalRef.current);
    };

    if (!window.daum || !window.daum.Postcode) {
      const script = document.createElement("script");
      script.src =
        "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
      script.async = true;
      script.onload = loadDaumPostcode;
      document.body.appendChild(script);
    } else {
      loadDaumPostcode();
    }
  }, [showAddressWindow]);

  useEffect(() => {
    if (info) {
      form.setFieldsValue({
        customerName: info.name,
        phone: info.phoneNumber,
        email: info.email,
        zip: info.zipCode,
        address1: info.address,
        relation: info.relationship,
        memo: info.memo,
      });
      setIsPregnant(info?.isPregnant || false);
      setDueDate(info?.dueDate);
    }
    if (open && info) {
      form.setFieldsValue({
        customerName: info.name,
        phone: info.phoneNumber,
        email: info.email,
        zip: info.zipCode,
        address1: info.address,
        relation: info.relationship,
        memo: info.memo,
      });
      setIsPregnant(info?.isPregnant || false);
      setDueDate(info?.dueDate);
    }
  }, [info, open]);
  return (
    <Drawer
      title="고객정보 수정"
      width={500}
      id="prospectInfoDrawerWrapper"
      open={open}
      onClose={() => {
        setOpenedDrawer("");
        form.resetFields();
        setShowAddressWindow(false);
        setChildInfo([]);
        setIsPregnant(false);
        setDueDate("");
      }}
    >
      <Form
        form={form}
        name="prospect-info"
        layout="vertical"
        className="mb-16"
      >
        <Form.Item label="고객명" name="customerName" rules={[requiredRule]}>
          <Input />
        </Form.Item>
        <Form.Item label="전화번호" name="phone" rules={[requiredRule]}>
          <Input />
        </Form.Item>
        <Form.Item label="이메일" name="email">
          <Input placeholder="이메일을 입력해주세요." />
        </Form.Item>
        <Form.Item label="주소" required>
          <div className="flex justify-between mb-2 h-32px w-full">
            <Form.Item className="w-4/5" name="zip" rules={[requiredRule]}>
              <Input disabled />
            </Form.Item>
            <Form.Item className="ms-2">
              <Button
                icon={<SearchOutlined />}
                onClick={() => {
                  setShowAddressWindow(!showAddressWindow);
                }}
              >
                주소찾기
              </Button>
            </Form.Item>
          </div>
          <Form.Item
            name="address1"
            className="mt-0 mb-2"
            rules={[requiredRule]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item name="address2" className="mt-0">
            <Input />
          </Form.Item>
        </Form.Item>
        <Form.Item label="자녀와의 관계" name="relation" rules={[requiredRule]}>
          <Select
            options={[
              { label: "엄마", value: "모" },
              { label: "아빠", value: "부" },
              { label: "그 외", value: "기타" },
            ]}
          />
        </Form.Item>
        <Table
          columns={columns}
          size="small"
          className={`${childInfoMissing ? "border-table" : ""}`}
          dataSource={childInfo}
          pagination={false}
        />
        {childInfoMissing && (
          <p className="warn-text">This field is required!</p>
        )}
        <Form.Item label="메모" name="memo" className="mt-4">
          <TextArea maxLength={100} showCount />
        </Form.Item>
      </Form>
      <DrawerFooter
        handleCancel={() => {
          setOpenedDrawer("");
          form.resetFields();
          setShowAddressWindow(false);
          setChildInfo([]);
          setIsPregnant(false);
          setDueDate("");
        }}
        handleSubmit={() => {
          if (!childInfoMissing) {
            form
              .validateFields()
              .then(() => {
                handleSubmit();
              })
              .catch((e) => console.log(e));
          }
        }}
      />
      {showAddressWindow && (
        <div className="address-modal">
          <div className="flex justify-between items-center title">
            <p>주소검색</p>
            <div
              onClick={() => {
                setShowAddressWindow(false);
              }}
            >
              <CloseOutlined />
            </div>
          </div>
          <div ref={modalRef} className="address-modal-2" />
        </div>
      )}
    </Drawer>
  );
};

export default ProspectInfoDrawer;
