export const monthMapping: Record<number, string> = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

interface DetailType {
  branchCode: string;
  branchName: string;
  category: string;
  companyNumber: string;
  customerName: string;
  endTime: string | null;
  leadNo: number;
  name: string;
  startTime: string;
}

export interface CalendarDataType {
  date: string;
  data: DetailType[];
}

export interface EventDataType {
  date: string;
  data: DetailType;
}

export const eventTypeList = [
  { value: "appo", color: "#D2A06E", text: "아포" },
  { value: "demo", color: "#00B140", text: "데모" },
  { value: "meeting", color: "#5D6CF8", text: "미팅" },
  { value: "education", color: "#FF6900", text: "교육" },
  { value: "other", color: "#595959", text: "Other" },
];
