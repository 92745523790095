import ContentTitle from "../../../components/ui/ContentTitle";
import {
  Button,
  Form,
  Input,
  Table,
  Switch,
  Drawer,
  Select,
  message,
  Modal,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  RedoOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import requiredRule from "../../../utils/formRules/requiredRule";
import { apiCall } from "../../../utils/apiCall";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import {
  LeadDetailDataType,
  LeadDuplicationType,
  LeadDistributionType,
  LeadRecordType,
  LeadRefundType,
  BranchInfoType,
} from "./types";
import {
  refundReasonList,
  deleteStatusList,
  refundStatusList,
} from "./options";
import DrawerFooter from "../../../components/layout/drawer/DrawerFooter";
import ProspectInfoDrawer from "./prospectInfoDrawer";
import LeadInfoDrawer from "./leadInfoDrawer";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import fetchAdvisorsData from "./utils/fetchAdvisorsData";
import {
  convertToAdvisorTreeSelectOptions,
  convertToBranchOptions,
  convertAssigneeOptions,
} from "./utils/convertToAdvisorTreeSelectOptions";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import Spinner from "../../../components/layout/spinner/Spinner";
import "./leadDetail.scss";
import TextArea from "antd/es/input/TextArea";

const LeadDetail = () => {
  const [details, setDetails] = useState<LeadDetailDataType>();
  const [isLoading, setIsLoading] = useState(true);
  const [isRefund, setIsRefund] = useState(false);
  const { leadNo } = useParams();
  const navigate = useNavigate();
  const [advisors, setAdvisors] = useState<BranchInfoType[]>([]);
  const [branchSelectOptions, setBranchSelectOptions] = useState<any>([]);
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [assigneeSelectOptions, setAssigneeSelectOptions] = useState<any>([]);
  const [selectedAssignee, setSelectedAssignee] = useState<string>("");
  const [prospectForm] = Form.useForm();
  const [drawerForm] = Form.useForm();
  const [refundForm] = Form.useForm();
  const [openedDrawer, setOpenedDrawer] = useState("");
  const [modalOpen, setModalOpen] = useState("");
  const [consultToBeDeleted, setConsultToBeDeleted] = useState({
    time: "",
    type: "",
    companyNumber: "",
  });
  const [leadInformationForm] = Form.useForm();

  const header = {
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
    },
  };

  const fetchLeadDetail = async (leadNo: string) => {
    const data = await apiCall({
      method: "GET",
      endPoint: process.env.REACT_APP_SALES_ADMIN + "/leads/admin/" + leadNo,
      header: header,
    });
    if (data) {
      console.log("details", data);
      setIsLoading(false);
      setDetails(data);
    }
  };

  const changeKeepStatus = async (keep: boolean) => {
    if (leadNo && details) {
      if (keep) {
        await apiCall({
          method: "POST",
          endPoint: process.env.REACT_APP_SALES_ADMIN + "/leads/admin/keep",
          header: header,
          payload: {
            data: [
              {
                leadNo: parseFloat(leadNo),
                companyNumber:
                  details.leadDistributionList[
                    details.leadDistributionList.length - 1
                  ].companyNumber,
                branchCode:
                  details.leadDistributionList[
                    details.leadDistributionList.length - 1
                  ].branchCode,
              },
            ],
          },
        });
      } else {
        await apiCall({
          method: "PATCH",
          endPoint: process.env.REACT_APP_SALES_ADMIN + "/leads/admin/keep",
          header: header,
          payload: {
            data: [
              {
                leadNo: parseFloat(leadNo),
                companyNumber:
                  details.leadDistributionList[
                    details.leadDistributionList.length - 1
                  ].companyNumber,
              },
            ],
          },
        });
      }
      fetchLeadDetail(leadNo);
    }
  };

  const handleChangeAssignee = async () => {
    if (leadNo) {
      const payload = {
        leadNos: [parseFloat(leadNo)],
        companyNumber: selectedAssignee,
      };
      await apiCall({
        method: "PATCH",
        header: header,
        payload: payload,
        endPoint: process.env.REACT_APP_SALES_ADMIN + "/leads/admin/assignees",
      });
      message.success(renderAntDMessageConfig("담당자 변경이 완료되었습니다."));
      setOpenedDrawer("");
      setSelectedAssignee("");
      setSelectedBranch("");
      fetchLeadDetail(leadNo);
    }
  };

  const handleDeleteConsult = async () => {
    if (leadNo) {
      await apiCall({
        method: "PATCH",
        header: header,
        endPoint:
          process.env.REACT_APP_SALES_ADMIN + "/leads/admin/records/deleted",
        payload: {
          type: consultToBeDeleted.type,
          leadNo: parseFloat(leadNo),
          companyNumber: consultToBeDeleted.companyNumber,
        },
      });
      message.success(renderAntDMessageConfig("상담 기록을 삭제하였습니다."));
      setModalOpen("");
      setConsultToBeDeleted({ time: "", companyNumber: "", type: "" });
      fetchLeadDetail(leadNo);
    }
  };

  const handleSubmitRefund = async () => {
    if (leadNo) {
      const { reason, addition } = refundForm.getFieldsValue();
      await apiCall({
        method: "POST",
        header: header,
        endPoint: process.env.REACT_APP_SALES_ADMIN + "/leads/admin/refund",
        payload: {
          leadNo: parseFloat(leadNo),
          reasonCode: reason,
          memo: addition,
          companyNumber: details?.leadInformation.companyNumber,
        },
      });
      setOpenedDrawer("");
      refundForm.resetFields();
      fetchLeadDetail(leadNo);
    }
  };

  const handleImmediateRefundOrDelete = async () => {
    if (leadNo) {
      await apiCall({
        method: "PATCH",
        header: header,
        endPoint:
          process.env.REACT_APP_SALES_ADMIN + "/leads/admin/refund/execute",
        payload: {
          leadNo: parseFloat(leadNo),
          companyNumber: details?.leadInformation.companyNumber,
        },
      });
      if (isRefund) {
        message.success(
          renderAntDMessageConfig("리펀드 처리가 완료되었습니다.")
        );
      } else {
        message.success(renderAntDMessageConfig("삭제 처리가 완료되었습니다."));
      }
      setModalOpen("");
      fetchLeadDetail(leadNo);
    }
  };

  const handleCancelRefundOrDelete = async () => {
    if (leadNo) {
      await apiCall({
        method: "PATCH",
        header: header,
        endPoint: process.env.REACT_APP_SALES_ADMIN + "/leads/admin/refund",
        payload: {
          leadNo: parseFloat(leadNo),
          companyNumber: details?.leadInformation.companyNumber,
        },
      });
      if (isRefund) {
        message.success(
          renderAntDMessageConfig("리펀드 요청을 철회하였습니다.")
        );
      } else {
        message.success(renderAntDMessageConfig("삭제 요청을 철회하였습니다."));
      }
      fetchLeadDetail(leadNo);
      setModalOpen("");
    }
  };

  useEffect(() => {
    setIsRefund(
      details?.leadInformation.cldl === "CL" &&
        details.leadInformation.term === "fresh"
    );
  }, [details]);

  useEffect(() => {
    fetchAdvisorsData(header, setAdvisors);
  }, []);

  useEffect(() => {
    if (advisors.length > 0) {
      setBranchSelectOptions(convertToBranchOptions(advisors));
    }
  }, [advisors]);

  useEffect(() => {
    if (selectedBranch) {
      const branchData = advisors.find((v) => v.branchCode === selectedBranch);
      if (branchData)
        setAssigneeSelectOptions(convertAssigneeOptions(branchData));
    }
  }, [selectedBranch]);

  const duplicationTableColumns: ColumnsType<LeadDuplicationType> = [
    {
      title: "리드번호",
      dataIndex: "leadNo",
      key: "leadNo",
      render: (value: string) => (
        <Button
          type="link"
          onClick={() => {
            navigate(`/sales188/lead-detail/${value}`);
          }}
        >
          {value}
        </Button>
      ),
    },
    {
      title: "리드유형",
      render: (_, record) => `${record.cldl} (${record.leadType}) `,
    },
    {
      title: "리드유입",
      dataIndex: "media",
      key: "media",
    },
    {
      title: "리드 생성일",
      dataIndex: "createDate",
      key: "createDate",
      render: (date, _) => dayjs(date).format("YYYY-MM-DD"),
    },
    {
      title: "지사",
      render: (_, record) => `${record.branchName}(${record.branchCode})`,
    },
    {
      title: "담당자",
      render: (_, record) => `${record.name}(${record.companyNumber})`,
    },
    {
      title: "계약현황",
      dataIndex: "contractStatus",
      key: "contractStatus",
      render: (v, record) =>
        v === "contracted"
          ? `계약완료(${dayjs(record.contractDate).format("YYYY-MM-DD")})`
          : "",
    },
    {
      title: "중복사유",
      dataIndex: "memo",
      key: "memo",
    },
  ];

  const distributionTableColumns: ColumnsType<LeadDistributionType> = [
    {
      title: "",
      render: (_, __, index) => index + 1,
    },
    {
      title: "리드주기",
      dataIndex: "term",
      key: "term",
    },
    {
      title: "지사",
      render: (_, record) => `${record.branchName}(${record.branchCode})`,
    },
    {
      title: "담당자",
      render: (_, record) => `${record.name}(${record.companyNumber})`,
    },
    {
      title: "배분일시",
      dataIndex: "distributionDate",
      key: "distributionDate",
      render: (v) => (v ? dayjs(v).format("YYYY-MM-DD") : ""),
    },
    {
      title: "마감일",
      dataIndex: "expiryDate",
      key: "expiryDate",
      render: (v) => (v ? dayjs(v).format("YYYY-MM-DD") : ""),
    },
    {
      title: "변경일시",
      dataIndex: "changeDate",
      key: "changeDate",
      render: (v) => (v ? dayjs(v).format("YYYY-MM-DD HH:mm:ss") : ""),
    },
    {
      title: "비고",
      dataIndex: "memo",
      key: "memo",
    },
  ];

  const consultRecordTableColumns: ColumnsType<LeadRecordType> = [
    {
      title: "상담단계",
      dataIndex: "type",
      key: "type",
      render: (v) => (v ? (v === "appo" ? "아포" : "데모") : "아포취소"),
    },
    {
      title: "기록삭제",
      render: (_, v) => (
        <Button
          type="link"
          onClick={() => {
            setModalOpen("1");
            setConsultToBeDeleted({
              time: v.createDate
                ? dayjs(v.createDate).format("YYYY-MM-DD HH:mm:ss")
                : "",
              type: v.type || "",
              companyNumber: v.companyNumber || "",
            });
          }}
        >
          삭제
        </Button>
      ),
    },
    {
      title: "리드주기",
      dataIndex: "term",
      key: "term",
    },
    {
      title: "지사",
      render: (_, record) => `${record.branchName}(${record.branchCode})`,
    },
    {
      title: "담당자",
      render: (_, record) => `${record.name}(${record.companyNumber})`,
    },
    {
      title: "등록일",
      dataIndex: "createDate",
      key: "createDate",
      render: (v) => (v ? dayjs(v).format("YYYY-MM-DD HH:mm:ss") : ""),
    },
    {
      title: "상담 날짜 & 시간",
      dataIndex: "scheduledDate",
      key: "scheduledDate",
      render: (v) =>
        v
          ? dayjs(v)
              .format("YYYY-MM-DD A h:mm:ss")
              .replace("AM", "오전")
              .replace("PM", "오후")
          : "",
    },
    {
      title: "상담대상",
      dataIndex: "consultWith",
      key: "consultWith",
    },
    {
      title: "고객반응",
      dataIndex: "feedback",
      key: "feedback",
    },
    {
      title: "고민내용",
      dataIndex: "concern",
      key: "concern",
    },
    {
      title: "상담소요시간",
      dataIndex: "timeSpent",
      key: "timeSpent",
    },
    {
      title: "메모",
      dataIndex: "memo",
      key: "memo",
    },
  ];

  const deleteRefundTableColumns: ColumnsType<LeadRefundType> = [
    {
      title: "리드주기",
      dataIndex: "term",
      key: "term",
    },
    {
      title: "지사",
      render: (_, record) => `${record.branchName}(${record.branchCode})`,
    },
    {
      title: "담당자",
      render: (_, record) => `${record.name}(${record.companyNumber})`,
    },
    {
      title: "리펀드/삭제 신청 사유",
      dataIndex: "reasonCode",
      key: "reasonCode",
      render: (v) =>
        refundReasonList.find((option) => option.value === v)?.label,
    },
    {
      title: "처리현황",
      dataIndex: "status",
      key: "status",
      render: (v, record) =>
        details?.leadInformation.cldl === "CL" && record.term === "fresh"
          ? refundStatusList.find((option) => option.value === v)?.label
          : deleteStatusList.find((option) => option.value === v)?.label,
    },
    {
      title: "처리일자",
      dataIndex: "receiptDate",
      key: "receiptDate",
      render: (v) =>
        v
          ? dayjs(v)
              .format("YYYY-MM-DD A h:mm:ss")
              .replace("AM", "오전")
              .replace("PM", "오후")
          : "",
    },
    {
      title: "Action",
      render: (_, record) => (
        <div className="flex">
          <Button
            type="link"
            onClick={() => {
              setModalOpen("3");
            }}
          >
            즉시처리
          </Button>
          <Button
            type="link"
            onClick={() => {
              setModalOpen("4");
            }}
          >
            요청철회
          </Button>
        </div>
      ),
    },
  ];
  useEffect(() => {
    if (leadNo) fetchLeadDetail(leadNo);
  }, [leadNo]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div id="leadDetailWrapper">
      <div className="sticky-top">
        <ContentTitle
          title={`리드 상세`}
          returnTitle={`리드 상세`}
          minorTitle={`${leadNo} ${
            details ? details.prospectInformation.name + " 님" : ""
          }`}
          onReturn={() => {
            navigate(-1);
          }}
        />
      </div>
      <div className="table-container">
        <div className="prospect block">
          <div className="flex justify-content-space-between vertical-center">
            <p className="title">고객정보</p>
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setOpenedDrawer("1");
              }}
            >
              고객정보 수정
            </Button>
          </div>
          <Form form={prospectForm} name="prospect-form">
            <div className="flex mt-20">
              <Form.Item
                label="회원번호"
                className="w-32"
                name="customerNumber"
              >
                <p>{details?.prospectInformation.customerNumber}</p>
              </Form.Item>
              <Form.Item label="계약일" className="w-32" name="contractDate">
                <p>{details?.prospectInformation.contractDate}</p>
              </Form.Item>
            </div>
            <div className="flex">
              <Form.Item label="고객명" className="w-32" name="name">
                <p>{details?.prospectInformation.name}</p>
              </Form.Item>
              <Form.Item label="전화번호" className="w-32" name="phoneNumber">
                <p>{details?.prospectInformation.phoneNumber}</p>
              </Form.Item>
              <Form.Item label="이메일" className="w-32" name="email">
                <p>{details?.prospectInformation.email}</p>
              </Form.Item>
            </div>
            <Form.Item label="주소" name="address">
              <p>{details?.prospectInformation.address}</p>
            </Form.Item>
            <div className="flex">
              <Form.Item
                label="자녀와의 관계"
                className="w-32"
                name="relationship"
              >
                <p>{details?.prospectInformation.relationship}</p>
              </Form.Item>
              <Form.Item label="임신여부" className="w-32" name="isPregnant">
                <p>{details?.prospectInformation.isPregnant}</p>
              </Form.Item>
            </div>
            <div className="flex">
              <Form.Item label="자녀1" className="w-32" name="child1">
                <p>{`${details?.prospectInformation.children[0]?.name}${
                  details?.prospectInformation.children[0]?.birthday
                    ? ` (생일: ${dayjs(
                        details?.prospectInformation.children[0]?.birthday
                      ).format("YYYY-MM-DD")})`
                    : ""
                }`}</p>
              </Form.Item>
              {details?.prospectInformation.children &&
                details?.prospectInformation.children.length > 1 && (
                  <Form.Item label="자녀1" className="w-32" name="child1">
                    <p>{`${details?.prospectInformation.children[1]?.name}${
                      details?.prospectInformation.children[1]?.birthday
                        ? ` (생일: ${dayjs(
                            details?.prospectInformation.children[1]?.birthday
                          ).format("YYYY-MM-DD")})`
                        : ""
                    }`}</p>
                  </Form.Item>
                )}
              {details?.prospectInformation.children &&
                details?.prospectInformation.children.length > 2 && (
                  <Form.Item label="자녀1" className="w-32" name="child1">
                    <p>{`${details?.prospectInformation.children[2]?.name}${
                      details?.prospectInformation.children[2]?.birthday
                        ? ` (생일: ${dayjs(
                            details?.prospectInformation.children[2]?.birthday
                          ).format("YYYY-MM-DD")})`
                        : ""
                    }`}</p>
                  </Form.Item>
                )}
            </div>
            <Form.Item label="메모" name="memo">
              <p>{details?.prospectInformation.memo}</p>
            </Form.Item>
          </Form>
        </div>
        <div className="leadInfo block">
          <div className="flex justify-content-space-between vertical-center">
            <p className="title">리드정보</p>
            <Button
              onClick={() => {
                setOpenedDrawer("2");
              }}
              icon={<EditOutlined />}
            >
              리드삭제
            </Button>
          </div>
          <Form form={leadInformationForm} name="lead-information-form">
            <div className="flex mt-20">
              <Form.Item label="리드번호" className="w-32" name="leadNumber">
                <p>{leadNo}</p>
              </Form.Item>
              <Form.Item label="현재 담당자 이름" className="w-32" name="name">
                <p>{`${details?.leadInformation.name} ${
                  details?.leadInformation.companyNumber
                    ? `(${details?.leadInformation.companyNumber})`
                    : ""
                }`}</p>
              </Form.Item>
              <Form.Item label="리드주기" className="w-32" name="term">
                <p>{details?.leadInformation.term}</p>
              </Form.Item>
            </div>
            <div className="flex">
              <Form.Item label="리드유형" className="w-32" name="leadType">
                <p>{`${details?.leadInformation.cldl} ${
                  details?.leadInformation.leadType
                    ? `(${details?.leadInformation.leadType})`
                    : ""
                }`}</p>
              </Form.Item>
              <Form.Item label="미디어 (리드유입)" name="media">
                <p>
                  {details?.leadInformation.mediaCode}
                  {`(${details?.leadInformation.media})`}
                </p>
              </Form.Item>
            </div>
            <div className="flex">
              <Form.Item
                label="고객명"
                className="w-32"
                name="distributionDate"
              >
                <p>
                  {dayjs(details?.leadInformation.distributionDate).format(
                    "YYYY-MM-DD"
                  )}
                </p>
              </Form.Item>
              <Form.Item label="전화번호" className="w-32" name="expiryDate">
                <p>
                  {dayjs(details?.leadInformation.expiryDate).format(
                    "YYYY-MM-DD"
                  )}
                </p>
              </Form.Item>
              <Form.Item label="이메일" className="w-32" name="createDate">
                <p>
                  {dayjs(details?.leadInformation.createDate).format(
                    "YYYY-MM-DD"
                  )}
                </p>
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className="duplicateInfo block">
          <p className="title">중복 리드 목록</p>
          <Table
            className="mt-20"
            columns={duplicationTableColumns}
            dataSource={details?.leadDuplicationList}
            scroll={{ x: "max-content" }}
          />
        </div>
        <div className="distributionInfo block">
          <div className="flex justify-content-space-between vertical-center">
            <p className="title">리드 배분 기록</p>
            <div className="flex vertical-center">
              <div className="flex">
                Keep:
                <Switch
                  className="ms-2"
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked={
                    details?.leadDistributionList[
                      details?.leadDistributionList.length - 1
                    ].isKeep
                  }
                  onChange={(e) => {
                    changeKeepStatus(e);
                  }}
                />
              </div>
              <Button
                className="me-5 ms-5"
                icon={<RedoOutlined />}
                onClick={() => {
                  if (
                    (details?.leadRefundList &&
                      details.leadRefundList.length === 0) ||
                    (details?.leadRefundList &&
                      details.leadRefundList[details.leadRefundList.length - 1]
                        .status === "canceled")
                  ) {
                    setOpenedDrawer("3");
                  } else {
                    setModalOpen("2");
                  }
                }}
              >
                담당자 변경
              </Button>
            </div>
          </div>
          <Table
            className="mt-20"
            columns={distributionTableColumns}
            dataSource={details?.leadDistributionList}
            scroll={{ x: "max-content" }}
          />
        </div>
        <div className="consultRecordInfo block">
          <p className="title">
            {details?.prospectInformation.name}님의 상담 기록
          </p>
          <Table
            className="mt-20"
            columns={consultRecordTableColumns}
            dataSource={details?.leadRecordList}
            scroll={{ x: "max-content" }}
          />
        </div>
        <div className="deleteRefundRecordInfo block">
          <div className="flex justify-content-space-between vertical-center">
            <p className="title">리드 리펀드/삭제 현황</p>
            <Button
              disabled={
                details?.leadRefundList &&
                details?.leadRefundList.length > 0 &&
                details?.leadRefundList[details.leadRefundList.length - 1]
                  .status !== "canceled"
              }
              icon={<UserDeleteOutlined />}
              onClick={() => {
                setOpenedDrawer("4");
              }}
            >
              리펀드/삭제 요청
            </Button>
          </div>
          <Table
            className="mt-20"
            columns={deleteRefundTableColumns}
            dataSource={details?.leadRefundList}
            scroll={{ x: "max-content" }}
          />
        </div>
      </div>
      <ProspectInfoDrawer
        open={openedDrawer === "1"}
        setOpenedDrawer={setOpenedDrawer}
        info={details?.prospectInformation}
        refetch={fetchLeadDetail}
        leadNo={leadNo}
      />
      <LeadInfoDrawer
        open={openedDrawer === "2"}
        setOpenedDrawer={setOpenedDrawer}
        info={details?.leadInformation}
        refetch={fetchLeadDetail}
        leadNo={leadNo}
      />
      <Drawer
        title="담당자 변경"
        width="500"
        open={openedDrawer === "3"}
        onClose={() => {
          setOpenedDrawer("");
          setSelectedAssignee("");
          setSelectedBranch("");
        }}
        footer={
          <DrawerFooter
            handleSubmit={() => {
              drawerForm
                .validateFields()
                .then(() => {
                  handleChangeAssignee();
                })
                .catch((e) => {
                  console.log(e);
                });
            }}
            handleCancel={() => {
              setOpenedDrawer("");
              setSelectedAssignee("");
              setSelectedBranch("");
            }}
            cancelButtonText="취소"
            submitButtonText="확인"
          />
        }
      >
        <Form form={drawerForm} name="change-assignee-drawer" layout="vertical">
          <Form.Item>
            지사를 선택한 후 해당 지사의 새 담당자를 선택해주세요.
          </Form.Item>
          <Form.Item label="지사" name="branch" rules={[requiredRule]}>
            <Select
              options={branchSelectOptions}
              placeholder="지사를 선택해주세요"
              onChange={(e) => {
                setSelectedBranch(e);
              }}
            />
          </Form.Item>
          <Form.Item label="새 담당자" rules={[requiredRule]} name="assignee">
            <Select
              options={assigneeSelectOptions}
              disabled={!selectedBranch}
              placeholder="변경할 담당자를 선택해주세요"
              onChange={(e) => {
                setSelectedAssignee(e);
              }}
            />
          </Form.Item>
        </Form>
      </Drawer>
      <Drawer
        open={openedDrawer === "4"}
        title="리드 리펀드/삭제 신청"
        width="500"
        onClose={() => {
          setOpenedDrawer("");
          refundForm.resetFields();
        }}
        footer={
          <DrawerFooter
            handleSubmit={() => {
              refundForm
                .validateFields()
                .then(() => {
                  handleSubmitRefund();
                })
                .catch((e) => console.log(e));
            }}
            handleCancel={() => {
              setOpenedDrawer("");
              refundForm.resetFields();
            }}
            cancelButtonText="취소"
            submitButtonText="확인"
          />
        }
      >
        <Form form={refundForm} name="refund-form" layout="vertical">
          <Form.Item
            label="사유"
            name="reason"
            rules={[{ required: true, message: "필수 항목을 입력해주세요." }]}
          >
            <Select
              options={refundReasonList}
              placeholder="리펀드/삭제 신청 사유를 선택해주세요."
            />
          </Form.Item>
          <Form.Item label="추가 내용 (optional)" name="addition">
            <TextArea />
          </Form.Item>
        </Form>
      </Drawer>
      <Modal
        centered
        open={["1", "3", "4"].includes(modalOpen)}
        closeIcon={null}
        footer={[
          <div className="onDelete-modal-button-group">
            <Button
              onClick={() => {
                setModalOpen("");
                setConsultToBeDeleted({
                  time: "",
                  type: "",
                  companyNumber: "",
                });
              }}
              style={{ marginRight: 8, marginLeft: 8, marginBottom: 4 }}
            >
              취소
            </Button>
            <Button
              type="primary"
              style={{ marginRight: 8, marginLeft: 8, marginBottom: 4 }}
              onClick={() => {
                if (modalOpen === "1") {
                  handleDeleteConsult();
                } else if (modalOpen === "3") {
                  handleImmediateRefundOrDelete();
                } else if (modalOpen === "4") {
                  handleCancelRefundOrDelete();
                }
              }}
            >
              삭제
            </Button>
          </div>,
        ]}
        width={417}
      >
        <div
          style={{
            display: "flex",
            height: 76,
            marginBottom: 24,
            marginRight: 8,
            marginLeft: 8,
            marginTop: 12,
          }}
        >
          <img
            alt="warning circle icon"
            src={require("../../../assets/icons/warning-circle.png")}
            style={{ width: 22, height: 22, marginRight: 16 }}
          />
          <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <p style={{ fontSize: 16, fontWeight: 500 }}>
              {modalOpen === "1"
                ? "상담 기록 삭제"
                : modalOpen === "3"
                ? isRefund
                  ? "리펀드 처리"
                  : "삭제 처리"
                : isRefund
                ? "리펀드 철회"
                : "삭제 철회"}
            </p>
            {modalOpen === "1" ? (
              <p style={{ lineHeight: 1.5 }}>
                <span className="font-bold">
                  {consultToBeDeleted.time + " "}
                </span>
                등록된
                <span className="font-bold">
                  {consultToBeDeleted.type === "appo" ? " 아포" : " 데모"}
                </span>{" "}
                기록을 삭제하시겠습니까?
                <br /> 삭제된 상담 기록은 다시 되돌릴 수 없습니다.
              </p>
            ) : modalOpen === "3" ? (
              isRefund ? (
                "리펀드 처리하시겠습니까?"
              ) : (
                "삭제 처리하시겠습니까?"
              )
            ) : isRefund ? (
              "리펀드 신청을 철회하시겠습니까?"
            ) : (
              "삭제 신청을 철회하시겠습니까?"
            )}
          </div>
        </div>
      </Modal>
      <Modal
        open={modalOpen === "2"}
        centered
        onCancel={() => {
          setModalOpen("");
        }}
        title="담당자 변경이 불가합니다."
        footer={[
          <Button
            type="primary"
            onClick={() => {
              setModalOpen("");
            }}
          >
            확인
          </Button>,
        ]}
      >
        <p>해당 리드의 리펀드/삭제 처리 중입니다. </p>
        <p className="mt-2">
          리펀드/삭제 신청 철회 후 담당자 변경이 가능합니다.
        </p>
      </Modal>
    </div>
  );
};

export default LeadDetail;
