import { apiCall } from "../../../../utils/apiCall";

export default async function fetchAdvisorsData(header: any, setAdvisors: any) {
  const data = await apiCall({
    method: "GET",
    endPoint:
      process.env.REACT_APP_SALES_ADMIN + "/leads/admin/options/advisors",
    header: header,
  });
  if (data) {
    setAdvisors(data);
  }
}
