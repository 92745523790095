import { DatePicker, Drawer, Form, Input, Select, message } from "antd";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import DrawerFooter from "../../../components/layout/drawer/DrawerFooter";
import { LeadInformationType } from "./types";
import requiredRule from "../../../utils/formRules/requiredRule";
import { useEffect, useState } from "react";
import { apiCall } from "../../../utils/apiCall";
import dayjs from "dayjs";

interface LeadInfoDrawerProps {
  open: boolean;
  setOpenedDrawer: any;
  info: LeadInformationType | undefined;
  refetch: any;
  leadNo: string | undefined;
}

interface MediaIdOptionsType {
  localMarketingId?: number;
  categoryId?: number;
  code: string;
  name: string;
}

const LeadInfoDrawer = ({
  open,
  setOpenedDrawer,
  info,
  refetch,
  leadNo,
}: LeadInfoDrawerProps) => {
  const [form] = Form.useForm();
  const [mediaId, setMediaId] = useState();
  const [mediaIdOptions, setMediaIdOptions] = useState([]);

  const fetchMediaId = async (leadNo: string) => {
    const header = {
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      },
    };
    const data = await apiCall({
      method: "GET",
      endPoint:
        process.env.REACT_APP_SALES_ADMIN +
        "/leads/admin/" +
        leadNo +
        "/mediaId",
      header: header,
    });
    if (data) {
      console.log("id", data.categoryId || data.localMarketingId);
      setMediaId(data.categoryId || data.localMarketingId);
    }
  };

  const fetchMediaIdOptions = async () => {
    const header = {
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      },
    };
    const data = await apiCall({
      method: "GET",
      endPoint:
        process.env.REACT_APP_SALES_ADMIN + "/leads/admin/options/media",
      header: header,
    });
    if (data) {
      const CLOptions = data?.CL;
      const BDOptions = data?.DLBD;
      const GDOptions = data?.DLGD;
      const LDOptions = data?.DLLD;
      const pool =
        info?.cldl === "CL"
          ? CLOptions
          : BDOptions.concat(GDOptions).concat(LDOptions);
      setMediaIdOptions(
        pool.map((item: MediaIdOptionsType) => {
          return {
            value: item.categoryId
              ? `c-${item.categoryId}`
              : `l-${item.localMarketingId}`,
            label: item.code ? `${item.code}(${item.name})` : item.name,
          };
        })
      );
    }
  };

  const handleSubmit = async () => {
    const { mediaCode, expiry } = form.getFieldsValue();
    if (mediaCode && expiry) {
      await apiCall({
        method: "PATCH",
        endPoint:
          process.env.REACT_APP_SALES_ADMIN +
          "/leads/admin/" +
          leadNo +
          "/information",
        header: {
          headers: {
            "x-api-key": process.env.REACT_APP_X_API_KEY,
          },
        },
        payload: {
          expiryDate: dayjs(expiry).format("YYYY-MM-DD"),
          ...(mediaCode.startsWith("l-")
            ? { localMarketingId: parseFloat(mediaCode.replace("l-", "")) }
            : { categoryId: parseFloat(mediaCode.replace("c-", "")) }),
        },
      });
      setOpenedDrawer("");
      message.success(
        renderAntDMessageConfig("리드정보 변경 사항이 저장되었습니다.")
      );
      form.resetFields();
      refetch(leadNo);
    }
  };

  useEffect(() => {
    if (info && open) {
      form.setFieldsValue({
        leadNo: leadNo,
        assignee: `${info.name}(${info.companyNumber})`,
        cycle: info.term,
        type: `${info.cldl}(${info.leadType})`,
        mediaCode:
          info?.cldl === "DL" && info.leadType === "GD"
            ? `l-${mediaId}`
            : `c-${mediaId}`,
        distribution: info.distributionDate
          ? dayjs(info.distributionDate).format("YYYY-MM-DD")
          : "",
        create: info.createDate
          ? dayjs(info.createDate).format("YYYY-MM-DD")
          : "",
        expiry: info.expiryDate ? dayjs(info.expiryDate) : undefined,
      });
    }
  }, [info, mediaId, open]);

  useEffect(() => {
    if (leadNo) {
      fetchMediaId(leadNo);
      fetchMediaIdOptions();
    }
  }, [leadNo]);
  return (
    <Drawer
      open={open}
      onClose={() => {
        setOpenedDrawer("");
        form.resetFields();
      }}
      id="leadInfoDrawerWrapper"
      title="리드정보 수정"
      width={500}
      footer={
        <DrawerFooter
          handleSubmit={handleSubmit}
          handleCancel={() => {
            setOpenedDrawer("");
            form.resetFields();
          }}
        />
      }
    >
      <Form form={form} name="lead-info" layout="vertical">
        <Form.Item label="리드번호" name="leadNo">
          <Input disabled />
        </Form.Item>
        <Form.Item label="현재 담당자 이름" name="assignee">
          <Input disabled />
        </Form.Item>
        <Form.Item label="리드주기" name="cycle">
          <Input disabled />
        </Form.Item>
        <Form.Item label="리드유형" name="type">
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="미디어 (리드유입)"
          name="mediaCode"
          rules={[requiredRule]}
        >
          <Select options={mediaIdOptions} />
        </Form.Item>
        <Form.Item label="배분일" name="distribution">
          <Input disabled />
        </Form.Item>
        <Form.Item label="마감일" name="expiry" rules={[requiredRule]}>
          <DatePicker className="w-full" />
        </Form.Item>
        <Form.Item label="최초생성일" name="create">
          <Input disabled />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default LeadInfoDrawer;
