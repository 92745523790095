import { useEffect, useRef, useState } from "react";
import { Form } from "antd";

import DrawerCustom from "../../../../components/custom/DrawerCustom";
import Spinner from "../../../../components/layout/spinner/Spinner";

import { useGetUploadProductVideosQuery } from "../../../../graphql/operations/trialMode/trialContentUpload/get-upload-product-videos";
import { useUpdateVideosSortIdMutation } from "../../../../graphql/operations/trialMode/trialContentUpload/update-videos-sort-id";
import { useCreateUploadVideoMutation } from "../../../../graphql/operations/trialMode/trialContentUpload/create-upload-video";
import { useUploadFileMutation } from "../../../../graphql/operations/upload-file";
import { useUpdateUploadVideoMutation } from "../../../../graphql/operations/trialMode/trialContentUpload/update-upload-video";
import { useDeleteUploadVideoMutation } from "../../../../graphql/operations/trialMode/trialContentUpload/delete-upload-video";

import { FormItemTitle } from "../../components/FormItems";
import VideoModel from "../../components/VideoModel";
import errorMessage from "../../utils/errorMessage";
import { DrawerActionType } from "../../types";
import TableMenu from "../components/TableMenu";
import DraggableTable from "../components/DraggableTable";
import {
  FormItemCategory,
  FormItemVideo,
  FormItemVideoThumbnail,
} from "../components/FormItems";
import { normalizeUploadVideoData } from "../utils/normalizeUploadData";
import {
  TrialContentUploadVideoFormDataType,
  TrialContentUploadVideoType,
} from "../types";
import { createVideoColumns } from "./columns";
import handleSubmit from "./handleSubmit";

export default function ContentVideo() {
  const videoRef = useRef<HTMLIFrameElement>(null);
  const [form] = Form.useForm();

  const [modalData, setModalData] = useState<TrialContentUploadVideoType>();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [drawerAction, setDrawerAction] = useState<DrawerActionType>("Create");
  const [videoData, setVideoData] = useState<TrialContentUploadVideoType[]>([]);

  const [uploadFile] = useUploadFileMutation();
  const {
    data,
    loading: loadingOfGet,
    error: errorOfGet,
    refetch,
  } = useGetUploadProductVideosQuery({
    fetchPolicy: "no-cache",
  });
  const [createVideo, { loading: loadingOfCreate }] =
    useCreateUploadVideoMutation();
  const [updateVideo, { loading: loadingOfUpdate }] =
    useUpdateUploadVideoMutation();
  const [updateSortId, { loading: loadingOfUpdateSortId }] =
    useUpdateVideosSortIdMutation();
  const [updateSortIdInDrawer, { loading: loadingOfUpdateSortIdInDrawer }] =
    useUpdateVideosSortIdMutation();
  const [deleteVideo, { loading: loadingOfDelete }] =
    useDeleteUploadVideoMutation();

  useEffect(() => {
    if (data) setVideoData(normalizeUploadVideoData(data));
  }, [data]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.src = modalData?.videoFileName
        ? `https://player.vimeo.com/video/${modalData.videoFileName}`
        : "";
    }
  }, [modalData]);

  if (errorOfGet) errorMessage();
  if (loadingOfGet || loadingOfUpdateSortId || loadingOfDelete)
    return <Spinner />;

  return (
    <div className="mx-6 py-5">
      <div className="px-6 bg-white">
        <TableMenu
          count={videoData.length}
          setOpenDrawer={setOpenDrawer}
          setDrawerAction={setDrawerAction}
        />

        <DraggableTable
          contentType="video"
          data={videoData}
          setData={setVideoData}
          columns={createVideoColumns(
            videoData,
            form,
            setModalData,
            setOpenDrawer,
            setDrawerAction,
            deleteVideo,
            refetch
          )}
          updateSortId={updateSortId}
          refetch={refetch}
        />

        <DrawerCustom
          title={`${drawerAction === "Create" ? "Create new" : "Edit"} video`}
          name="create-new-video"
          form={form}
          onFinish={(values: TrialContentUploadVideoFormDataType) => {
            handleSubmit({
              values,
              form,
              videoData,
              drawerAction,
              uploadFile,
              createVideo,
              updateVideo,
              updateSortId: updateSortIdInDrawer,
              refetch,
              setOpenDrawer,
            });
          }}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          isLoading={
            loadingOfCreate || loadingOfUpdate || loadingOfUpdateSortIdInDrawer
          }
        >
          <FormItemTitle name="videoTitle" />
          <FormItemCategory />
          <FormItemVideoThumbnail />
          <FormItemVideo
            name="introVideoFile"
            label="Intro video"
            extra="Enter the ID number of the video uploaded on Vimeo. Users can preview 10-second short videos."
          />
          <FormItemVideo
            name="videoFile"
            label="Video file"
            extra="Enter the ID number of the video uploaded on Vimeo."
          />
        </DrawerCustom>

        <VideoModel
          modalData={modalData}
          setModalData={setModalData}
          videoRef={videoRef}
        />
      </div>
    </div>
  );
}
