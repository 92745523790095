import {
  DatePicker,
  Form,
  Input,
  Button,
  TreeSelect,
  Select,
  Table,
  Checkbox,
  Dropdown,
  Drawer,
  message,
  Modal,
  Divider,
} from "antd";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";
import { Dispatch, SetStateAction } from "react";
import { useReactiveVar } from "@apollo/client";
import { __currentUser__ } from "../../../graphql/policies";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import { ServiceTypes } from "../../../types/types";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import requiredRule from "../../../utils/formRules/requiredRule";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import ContentTitle from "../../../components/ui/ContentTitle";
import { apiCall } from "../../../utils/apiCall";
import DrawerFooter from "../../../components/layout/drawer/DrawerFooter";
import {
  leadTypeOptions,
  cycleOptions,
  logStatusOptions,
  keepOptions,
  duplicateOptions,
  refundOptions,
  deleteOptions,
} from "./options";
import { LeadDataType, BranchInfoType } from "./types";
import { QueryFormValues } from "./types";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import convertToQueryString from "./utils/convertToQueryString";
import fetchAdvisorsData from "./utils/fetchAdvisorsData";
import {
  convertToAdvisorTreeSelectOptions,
  convertToBranchOptions,
  convertAssigneeOptions,
} from "./utils/convertToAdvisorTreeSelectOptions";
import "./leadList.scss";
interface SalesLeadListProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}
const LeadList = ({
  setSelectedService,
  setSelectSubItem,
}: SalesLeadListProps) => {
  useEffect(() => {
    setSelectedService(ServiceTypes.SALES_188);
    setSelectSubItem("리드 관리");
  }, []);
  const currentUser = useReactiveVar(__currentUser__);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 5,
    },
  });
  const navigate = useNavigate();
  const [leads, setLeads] = useState<LeadDataType[]>([]);
  const [selectedLeads, setSelectedLeads] = useState<number[]>([]);
  const [kept, setKept] = useState<number[]>([]);
  const [notFound, setNotFound] = useState<number[]>([]);
  const [beforeDistributionDate, setBeforeDistributionDate] = useState<
    number[]
  >([]);
  const [alreadyInContract, setAlreadyInContract] = useState<number[]>([]);
  const [finalSleepStage, setFinalSleepStage] = useState<number[]>([]);
  const [processingRefund, setProcessingRefund] = useState<number[]>([]);
  const [unexpectedError, setUnexpectedError] = useState<number[]>([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const isAllSelected =
    leads.length > 0 &&
    leads.every((item) => selectedLeads.includes(item.leadNo));
  const [advisors, setAdvisors] = useState<BranchInfoType[]>([]);
  const [visibleColumns, setVisibleColumns] = useState({
    leadNo: true,
    leadType: true,
    leadCycle: true,
    callLogStatus: true,
    isDuplicate: true,
    departName: true,
    assignee: true,
    refundStatus: true,
    deletionStatus: true,
    distributionDate: true,
    expiryDate: true,
    creationDate: true,
    media: true,
  });
  const [menuOpen, setMenuOpen] = useState(false);
  const [advisorsTreeSelectOptions, setAdvisorsTreeSelectOptions] =
    useState<any>([]);
  const [branchSelectOptions, setBranchSelectOptions] = useState<any>([]);
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [assigneeSelectOptions, setAssigneeSelectOptions] = useState<any>([]);
  const [selectedAssignee, setSelectedAssignee] = useState<string>("");
  const [isExpandedForm, setIsExpandedForm] = useState(false);
  const [form] = useForm();
  const [drawerForm] = useForm();
  const { RangePicker } = DatePicker;
  const header = {
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
    },
  };

  fetchAdvisorsData(header, setAdvisors);

  const fetchLeadData = async (values: QueryFormValues) => {
    const queryString = convertToQueryString(values);

    let endPoint = process.env.REACT_APP_SALES_ADMIN + "/leads/admin";
    endPoint += queryString;

    const data = await apiCall({
      method: "GET",
      endPoint: endPoint,
      header: header,
    });
    setLeads(data);
  };

  const handleSubmit = () => {
    fetchLeadData(form.getFieldsValue());
  };

  const handleBatchKeep = async () => {
    const keepData = selectedLeads.map((leadNo: number) => {
      const target = leads.find((item) => item.leadNo === leadNo);
      return {
        leadNo: leadNo,
        companyNumber: target?.companyNumber,
        branchCode: target?.departCode,
      };
    });
    const data = await apiCall({
      method: "POST",
      header: header,
      payload: { data: keepData },
      endPoint: process.env.REACT_APP_SALES_ADMIN + "/leads/admin/keep",
    });
    setShowModal(true);
    setKept(data?.success);
    setNotFound(
      Object.keys(data?.failed)
        .filter((key) => data.failed[key] === "NOT_FOUND")
        .map(Number)
    );
    setBeforeDistributionDate(
      Object.keys(data?.failed)
        .filter((key) => data.failed[key] === "BEFORE_DISTRIBUTION_DATE")
        .map(Number)
    );
    setAlreadyInContract(
      Object.keys(data?.failed)
        .filter((key) => data.failed[key] === "ALREADY_IN_CONTRACT")
        .map(Number)
    );
    setFinalSleepStage(
      Object.keys(data?.failed)
        .filter((key) => data.failed[key] === "FINAL_SLEEP_STAGE")
        .map(Number)
    );
    setProcessingRefund(
      Object.keys(data?.failed)
        .filter((key) => data.failed[key] === "PROCESSING_REFUND")
        .map(Number)
    );
    setUnexpectedError(
      Object.keys(data?.failed)
        .filter((key) => data.failed[key] === "UNEXPECTED_ERROR")
        .map(Number)
    );
    console.log("data", data);
  };

  const closeModal = () => {
    setShowModal(false);
    setKept([]);
    setNotFound([]);
    setBeforeDistributionDate([]);
    setAlreadyInContract([]);
    setFinalSleepStage([]);
    setUnexpectedError([]);
  };

  const handleDrawerClose = () => {
    setShowDrawer(false);
    setSelectedBranch("");
    drawerForm.resetFields();
  };

  const handleChangeAssignee = async () => {
    const payload = {
      leadNos: selectedLeads,
      companyNumber: selectedAssignee,
    };
    await apiCall({
      method: "PATCH",
      header: header,
      payload: payload,
      endPoint: process.env.REACT_APP_SALES_ADMIN + "/leads/admin/assignees",
    });
    handleDrawerClose();
    setSelectedLeads([]);
    message.success(renderAntDMessageConfig("담당자 변경이 완료되었습니다."));
    fetchLeadData(form.getFieldsValue());
  };

  const handleTitleCheckboxChange = (e: any) => {
    if (e.target.checked) {
      setSelectedLeads(leads.map((item) => item.leadNo));
    } else {
      setSelectedLeads([]);
    }
  };

  const handleRowCheckboxChange = (leadNo: number) => {
    setSelectedLeads((prev) => {
      if (prev.includes(leadNo)) {
        return prev.filter((item) => item !== leadNo);
      } else {
        return [...prev, leadNo];
      }
    });
  };

  const columns: ColumnsType<LeadDataType> = [
    {
      title: (
        <Checkbox
          checked={isAllSelected}
          indeterminate={selectedLeads.length > 0 && !isAllSelected} // 半選狀態
          onChange={handleTitleCheckboxChange}
        />
      ),
      fixed: "left",
      key: "check",
      dataIndex: "leadNo",
      render: (leadNo: number) => (
        <Checkbox
          checked={selectedLeads.includes(leadNo)}
          onChange={() => handleRowCheckboxChange(leadNo)}
        />
      ),
    },
    {
      title: "리드번호",
      fixed: "left",
      dataIndex: "leadNo",
      key: "leadNo",
      render: (value: string) => (
        <Button
          type="link"
          onClick={() => {
            navigate(`/sales188/lead-detail/${value}`);
          }}
        >
          {value}
        </Button>
      ),
      sorter: (a, b) => a.leadNo - b.leadNo,
      defaultSortOrder: "ascend",
    },
    {
      title: "리드유형",
      dataIndex: "leadType",
      key: "leadType",
      render: (_, record) => `${record.cldl}(${record.leadType})`,
    },
    {
      title: "리드주기",
      dataIndex: "leadCycle",
      key: "leadCycle",
      width: 90,
    },
    {
      title: "상담현황",
      dataIndex: "callLogStatus",
      key: "callLogStatus",
      render: (value, _) => {
        const option = logStatusOptions.find(
          (option) => option.value === value
        );
        return option ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                borderRadius: "50%",
                height: "6px",
                width: "6px",
                backgroundColor: option.color,
                marginRight: "8px",
              }}
            />
            {option.label}
          </div>
        ) : (
          <></>
        );
      },
    },
    {
      title: "중복여부",
      dataIndex: "isDuplicate",
      key: "isDuplicate",
      width: 90,
      render: (value: string) =>
        value ? <p>중복</p> : <p className="gray-text">N/A</p>,
    },
    {
      title: "지사",
      dataIndex: "departName",
      key: "departName",
      render: (_, record) => `${record.departName}(${record.departCode})`,
    },
    {
      title: "담당자",
      dataIndex: "assignee",
      key: "assignee",
      render: (_, record) => `${record.assignee}(${record.companyNumber})`,
    },
    {
      title: "리펀드 현황",
      dataIndex: "refundStatus",
      key: "refundStatus",
      width: 100,
      render: (value: string) =>
        value ? (
          value === "refunded" ? (
            <p>신청완료</p>
          ) : (
            <p>리펀드완료</p>
          )
        ) : (
          <p className="gray-text">N/A</p>
        ),
    },
    {
      title: "삭제 현황",
      dataIndex: "deletionStatus",
      key: "deletionStatus",
      width: 90,
      render: (value: string) =>
        value ? (
          value === "deleted" ? (
            <p>신청완료</p>
          ) : (
            <p>삭제완료</p>
          )
        ) : (
          <p className="gray-text">N/A</p>
        ),
    },
    {
      title: "배분일",
      dataIndex: "distributionDate",
      key: "distributionDate",
    },
    {
      title: "마감일",
      dataIndex: "expiryDate",
      key: "expiryDate",
    },
    {
      title: "생성일",
      dataIndex: "creationDate",
      key: "creationDate",
      sorter: (a, b) =>
        dayjs(a.creationDate).valueOf() - dayjs(b.creationDate).valueOf(),
    },
    {
      title: "리드유입",
      dataIndex: "media",
      key: "media",
    },
  ];
  const filteredColumns = columns.filter((column) => {
    const key = column.key;
    if (typeof key === "string" && key !== "check") {
      return visibleColumns[key as keyof typeof visibleColumns];
    } else {
      return true;
    }
  });
  const handleSelectAll = (checked: boolean) => {
    const updatedColumns = { ...visibleColumns };
    Object.keys(updatedColumns).forEach((key) => {
      if (key !== "leadNo") {
        updatedColumns[key as keyof typeof visibleColumns] = checked;
      }
    });
    setVisibleColumns(updatedColumns);
  };
  const columnMenuItems = [
    {
      key: "selectAll",
      label: (
        <Checkbox
          checked={Object.values(visibleColumns).every(
            (value, index) => index === 0 || value
          )}
          onClick={(e) => e?.stopPropagation()}
          onChange={(e) => handleSelectAll(e.target.checked)}
        >
          열 설정
        </Checkbox>
      ),
    },
    ...Object.keys(visibleColumns).map((key) => {
      const column = columns.find((col) => col.key === key);
      const title = column?.title;

      return {
        key,
        label: (
          <Checkbox
            checked={visibleColumns[key as keyof typeof visibleColumns]}
            onClick={(e) => e?.stopPropagation()}
            disabled={key === "leadNo"}
            onChange={(e) =>
              setVisibleColumns({
                ...visibleColumns,
                [key]: e.target.checked,
              })
            }
          >
            {typeof title === "string" ? title : ""}
          </Checkbox>
        ),
      };
    }),
  ];
  useEffect(() => {
    fetchAdvisorsData(header, setAdvisors);
  }, []);
  useEffect(() => {
    if (advisors.length > 0) {
      setAdvisorsTreeSelectOptions(convertToAdvisorTreeSelectOptions(advisors));
      setBranchSelectOptions(convertToBranchOptions(advisors));
    }
  }, [advisors]);
  useEffect(() => {
    if (selectedBranch) {
      const branchData = advisors.find((v) => v.branchCode === selectedBranch);
      if (branchData)
        setAssigneeSelectOptions(convertAssigneeOptions(branchData));
    }
  }, [selectedBranch]);

  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Lead Management"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div id="leadListWrapper">
      <div className="sticky-top">
        <ContentTitle title="리드조회 및 관리" />
      </div>
      <div className="table-container">
        <Form form={form} name="lead-inquiry-form" className="vertical-center">
          <div className="flex">
            <Form.Item label="리드유형" name="type">
              <TreeSelect
                treeData={leadTypeOptions}
                treeCheckable={true}
                multiple
                placeholder="리드유형 선택"
              />
            </Form.Item>
            <Form.Item label="리드주기" name="cycle">
              <Select options={cycleOptions} placeholder="리드주기 선택" />
            </Form.Item>
            <Form.Item label="담당자" name="assignee">
              <TreeSelect
                treeData={advisorsTreeSelectOptions}
                placeholder="지사&담당자 선택"
              />
            </Form.Item>
            {isExpandedForm ? (
              <Form.Item label="상담현황" name="log">
                <Select
                  options={logStatusOptions.map((option) => ({
                    value: option.value,
                    label: (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span
                          style={{
                            borderRadius: "50%",
                            height: "6px",
                            width: "6px",
                            backgroundColor: option.color,
                            marginRight: "8px",
                          }}
                        />
                        {option.label}
                      </div>
                    ),
                  }))}
                  placeholder="상담현황 선택"
                />
              </Form.Item>
            ) : (
              <div className="flex">
                <Button
                  className="form-button-left"
                  onClick={() => {
                    form.resetFields();
                  }}
                >
                  초기화
                </Button>
                <Button
                  className="form-button-right"
                  type="primary"
                  onClick={handleSubmit}
                >
                  조회
                </Button>
                <Button
                  type="link"
                  className="flex"
                  onClick={() => {
                    setIsExpandedForm(!isExpandedForm);
                  }}
                >
                  <div>모든필터</div>
                  <div>
                    <DownOutlined
                      style={{ fontSize: "8px", color: "#000", marginLeft: 5 }}
                    />
                  </div>
                </Button>
              </div>
            )}
          </div>
          {isExpandedForm && (
            <div className="flex mt-20">
              <Form.Item label="Keep상태" name="keep">
                <Select options={keepOptions} placeholder="Keep상태 선택" />
              </Form.Item>
              <Form.Item label="중복여부" name="duplicate">
                <Select
                  options={duplicateOptions}
                  placeholder="중복여부 선택"
                />
              </Form.Item>
              <Form.Item label="리펀드현황" name="refund">
                <Select options={refundOptions} placeholder="리펀드현황 선택" />
              </Form.Item>
              <Form.Item label="삭제현황" name="deletion">
                <Select options={deleteOptions} placeholder="삭제현황 선택" />
              </Form.Item>
            </div>
          )}
          {isExpandedForm && (
            <div className="flex mt-20">
              <Form.Item label="배분일" name="distribution">
                <RangePicker placeholder={["시작일", "종료일"]} />
              </Form.Item>
              <Form.Item label="마감일" name="expiry">
                <RangePicker placeholder={["시작일", "종료일"]} />
              </Form.Item>
              <Form.Item label="생성일" name="creation">
                <RangePicker placeholder={["시작일", "종료일"]} />
              </Form.Item>
              <Form.Item label="리드번호" name="number">
                <Input placeholder="리드번호" />
              </Form.Item>
            </div>
          )}
          {isExpandedForm && (
            <div className="flex mt-20">
              <Form.Item label="고객명" name="prospect">
                <Input placeholder="고객명" />
              </Form.Item>
              <Form.Item label="전화번호" name="phone">
                <Input placeholder="전화번호" />
              </Form.Item>
              <Form.Item label="자녀명" name="child">
                <Input placeholder="자녀명" />
              </Form.Item>
              <Form.Item label="주소" name="address">
                <Input placeholder="주소" />
              </Form.Item>
            </div>
          )}
          {isExpandedForm && (
            <div className="flex justify-content-end  mt-20">
              <Button
                className="form-button-left"
                onClick={() => {
                  form.resetFields();
                }}
              >
                초기화
              </Button>
              <Button
                className="form-button-right"
                type="primary"
                onClick={handleSubmit}
              >
                조회
              </Button>
              <Button
                type="link"
                className="flex"
                onClick={() => {
                  setIsExpandedForm(!isExpandedForm);
                }}
              >
                <div>{isExpandedForm ? "숨기기" : "모든필터"}</div>
                <div>
                  {isExpandedForm ? (
                    <UpOutlined
                      style={{ fontSize: "8px", color: "#000", marginLeft: 5 }}
                    />
                  ) : (
                    <DownOutlined
                      style={{ fontSize: "8px", color: "#000", marginLeft: 5 }}
                    />
                  )}
                </div>
              </Button>
            </div>
          )}
        </Form>
      </div>
      {leads.length > 0 && (
        <div className="table-container">
          <div className="table-inner-wrapper">
            <div className="flex justify-content-space-between">
              <div className="flex">
                <Button
                  disabled={selectedLeads.length === 0}
                  onClick={() => {
                    setShowDrawer(true);
                  }}
                >
                  담당자 변경
                </Button>
                <Button
                  disabled={selectedLeads.length === 0}
                  onClick={handleBatchKeep}
                  className="ms-3"
                >
                  Keep
                </Button>
                {selectedLeads.length > 0 && (
                  <p className="count-text">
                    Selected {selectedLeads.length} items
                  </p>
                )}
              </div>
              <div className="flex">
                <img
                  className="icon"
                  alt="refresh-icon"
                  src={require("../../../assets/icons/refresh.png")}
                  onClick={handleSubmit}
                />
                <img
                  className="icon"
                  alt="expand-icon"
                  src={require("../../../assets/icons/expand.png")}
                />
                <Dropdown
                  menu={{
                    items: columnMenuItems,
                  }}
                  trigger={["click"]}
                  open={menuOpen}
                  onOpenChange={(open) => {
                    setMenuOpen(open);
                  }}
                >
                  <img
                    className="icon"
                    alt="setting-icon"
                    src={require("../../../assets/icons/setting.png")}
                  />
                </Dropdown>
              </div>
            </div>
            <Table
              className="mt-20"
              columns={filteredColumns}
              dataSource={leads}
              scroll={{ x: "max-content", y: 550 }}
            />
          </div>
        </div>
      )}

      <Drawer
        title="담당자 변경"
        width="500"
        open={showDrawer}
        onClose={handleDrawerClose}
        footer={
          <DrawerFooter
            handleSubmit={() => {
              drawerForm
                .validateFields()
                .then(() => {
                  handleChangeAssignee();
                })
                .catch((e) => {
                  console.log(e);
                });
            }}
            handleCancel={handleDrawerClose}
            cancelButtonText="취소"
            submitButtonText="확인"
          />
        }
      >
        <Form form={drawerForm} name="change-assignee-drawer" layout="vertical">
          <Form.Item>
            <p>총 {selectedLeads.length}개의 리드가 선택되었습니다.</p>
            <p className="mt-2">{selectedLeads.join()}</p>
          </Form.Item>
          <Form.Item>
            지사를 선택한 후 해당 지사의 새 담당자를 선택해주세요.
          </Form.Item>
          <Form.Item label="지사" name="branch" rules={[requiredRule]}>
            <Select
              options={branchSelectOptions}
              placeholder="지사를 선택해주세요"
              onChange={(e) => {
                setSelectedBranch(e);
              }}
            />
          </Form.Item>
          <Form.Item label="새 담당자" rules={[requiredRule]} name="assignee">
            <Select
              options={assigneeSelectOptions}
              disabled={!selectedBranch}
              placeholder="변경할 담당자를 선택해주세요"
              onChange={(e) => {
                setSelectedAssignee(e);
              }}
            />
          </Form.Item>
        </Form>
      </Drawer>
      <Modal
        open={showModal}
        centered
        title="Keep 처리 결과"
        onCancel={closeModal}
        footer={[
          <Button key="ok" type="primary" onClick={closeModal}>
            OK
          </Button>,
        ]}
      >
        <Divider className="mt-3" />
        {notFound.length > 0 ? (
          <p>{notFound.join() + ""}: 이미 Keep 된 리드로 중복신청 불가</p>
        ) : null}
        {kept.length > 0 ? (
          <p className="mt-1">{kept.join() + ""}: Keep 완료</p>
        ) : null}
        {beforeDistributionDate.length > 0 ? (
          <p className="mt-1">
            {beforeDistributionDate.join() + ""}: 아직 배분이 안된 리드로 신청
            불가
          </p>
        ) : null}
        {alreadyInContract.length > 0 ? (
          <p className="mt-1">
            {alreadyInContract.join() + ""}: 이미 계약이 완료된 리드로 신청 불가
          </p>
        ) : null}
        {finalSleepStage.length > 0 ? (
          <p className="mt-1">
            {finalSleepStage.join() + ""}: Sleep 리드로 신청 불가
          </p>
        ) : null}
        {processingRefund.length > 0 ? (
          <p className="mt-1">
            {processingRefund.join() + ""}: 이미 Keep된 리드로 중복신청 불가
          </p>
        ) : null}
        {unexpectedError.length > 0 ? (
          <p className="mt-1">
            {unexpectedError.join() + ""}: 예상치 못한 오류 발생
          </p>
        ) : null}
        <Divider className="mt-5" />
      </Modal>
    </div>
  );
};

export default LeadList;
