import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpgradePaymentListQueryVariables = Types.Exact<{
  market: Types.Scalars['String']['input'];
  memberNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpgradePaymentListQuery = { __typename?: 'Query', upgradePaymentList: Array<{ __typename?: 'MemberUpgradePaymentResponse', orderId: string, memberNo: string, originalPackage: string, upgradePackage: string, addBeamProjector: number, addDongle: number, paymentAmount: number, paidAmount: number, paymentStatus: string, paymentDate?: any | null, canceledDate?: any | null, isChangeAddress?: boolean | null, createdDate?: any | null }> };


export const UpgradePaymentListDocument = gql`
    query UpgradePaymentList($market: String!, $memberNumber: String) {
  upgradePaymentList(parameter: {market: $market, memberNumber: $memberNumber}) {
    orderId
    memberNo
    originalPackage
    upgradePackage
    addBeamProjector
    addDongle
    paymentAmount
    paidAmount
    paymentStatus
    paymentDate
    canceledDate
    isChangeAddress
    createdDate
  }
}
    `;

/**
 * __useUpgradePaymentListQuery__
 *
 * To run a query within a React component, call `useUpgradePaymentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpgradePaymentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpgradePaymentListQuery({
 *   variables: {
 *      market: // value for 'market'
 *      memberNumber: // value for 'memberNumber'
 *   },
 * });
 */
export function useUpgradePaymentListQuery(baseOptions: Apollo.QueryHookOptions<UpgradePaymentListQuery, UpgradePaymentListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpgradePaymentListQuery, UpgradePaymentListQueryVariables>(UpgradePaymentListDocument, options);
      }
export function useUpgradePaymentListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpgradePaymentListQuery, UpgradePaymentListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpgradePaymentListQuery, UpgradePaymentListQueryVariables>(UpgradePaymentListDocument, options);
        }
export type UpgradePaymentListQueryHookResult = ReturnType<typeof useUpgradePaymentListQuery>;
export type UpgradePaymentListLazyQueryHookResult = ReturnType<typeof useUpgradePaymentListLazyQuery>;
export type UpgradePaymentListQueryResult = Apollo.QueryResult<UpgradePaymentListQuery, UpgradePaymentListQueryVariables>;