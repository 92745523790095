import ContentTitle from "../../../components/ui/ContentTitle";
import { useState, useEffect } from "react";
import {
  AdvisorsRegionDataType,
  RegionDataType,
  firstLevelKorean,
  secondLevelKorean,
} from "./types";
import { ColumnsType } from "antd/es/table";
import {
  Tabs,
  Button,
  Table,
  Checkbox,
  Tag,
  Modal,
  Cascader,
  Divider,
  message,
} from "antd";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";
import { Dispatch, SetStateAction } from "react";
import { useReactiveVar } from "@apollo/client";
import { __currentUser__ } from "../../../graphql/policies";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import { ServiceTypes } from "../../../types/types";
import { apiCall } from "../../../utils/apiCall";
import "./AdvisorRegion.scss";

interface AdvisorRegionProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const AdvisorRegion = ({
  setSelectedService,
  setSelectSubItem,
}: AdvisorRegionProps) => {
  useEffect(() => {
    setSelectedService(ServiceTypes.SALES_188);
    setSelectSubItem("시스템 관리");
  }, []);
  const [advisorsRegionData, setAdvisorRegionData] = useState<
    AdvisorsRegionDataType[]
  >([]);
  const [regionsOptions, setRegionsOptions] = useState<CascaderOption[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedAdvisors, setSelectedAdvisors] = useState<string[]>([]);

  const currentUser = useReactiveVar(__currentUser__);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 5,
    },
  });

  const header = {
    headers: {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
    },
  };

  const fetchAdvisorsRegionData = async (isRefetch: boolean) => {
    const data = await apiCall({
      method: "GET",
      header: header,
      endPoint: process.env.REACT_APP_SALES_ADMIN + "/advisors/admin/regions",
    });
    if (data) {
      setAdvisorRegionData(data);
      if (!isRefetch) {
        setSelectedBranch(data[0].branchCode);
      }
    }
  };

  type RegionOptionsData = Record<string, string[]>; // API 返回的數據類型

  type CascaderOption = {
    value: string;
    label: string;
    children?: CascaderOption[];
  };

  const fetchRegionsOptions = async () => {
    const data: RegionOptionsData = await apiCall({
      method: "GET",
      header: header,
      endPoint:
        process.env.REACT_APP_SALES_ADMIN + "/advisors/admin/regions/options",
    });
    if (data) {
      const cascaderData: CascaderOption[] = Object.entries(data).map(
        ([parent, children]) => ({
          value: parent,
          label: firstLevelKorean[parent as keyof typeof firstLevelKorean],
          children: children.map((child: string) => ({
            value: child,
            label: secondLevelKorean[child as keyof typeof secondLevelKorean],
          })),
        })
      );
      setRegionsOptions(cascaderData);
    }
  };

  const handleTabChange = (key: string) => {
    setSelectedAdvisors([]);
    if (advisorsRegionData) {
      const selected = advisorsRegionData[Number(key) - 1];
      if (selected) {
        setSelectedBranch(selected.branchCode);
      }
    }
  };

  const handleRegionChange = (value: any) => {
    setSelectedRegions(value);
  };

  const handleAdvisorsRegionChange = async () => {
    let regionsList: any[] = [];
    selectedRegions.map((option: string[]) => {
      if (option.length > 1) {
        regionsList.push(option.join("/"));
      } else {
        const allSelectedRegion = regionsOptions.find(
          (item) => item.value === option[0]
        );
        if (allSelectedRegion && allSelectedRegion.children) {
          const regionsArray = allSelectedRegion.children.map((area) => {
            return `${allSelectedRegion.value}/${area.value}`;
          });
          regionsList = regionsList.concat(regionsArray);
        }
      }
    });
    const payload = {
      companyNumbers: selectedAdvisors,
      regions: regionsList,
    };
    await apiCall({
      method: "PATCH",
      header: header,
      payload: payload,
      endPoint: process.env.REACT_APP_SALES_ADMIN + "/advisors/admin/regions",
    });
    setModalOpen(false);
    message.success(renderAntDMessageConfig("담당지역 설정을 완료하였습니다."));
    setSelectedAdvisors([]);
    setSelectedRegions([]);
    fetchAdvisorsRegionData(true);
  };

  const handleTitleCheckboxChange = (e: any) => {
    if (e.target.checked) {
      setSelectedAdvisors(
        advisorsRegionData
          .find((branch) => branch.branchCode === selectedBranch)
          ?.advisors.map((advisor) => advisor.companyNumber) || []
      );
    } else {
      setSelectedAdvisors([]);
    }
  };

  const handleRowCheckboxChange = (companyNumber: string) => {
    setSelectedAdvisors((prev) => {
      if (prev.includes(companyNumber)) {
        return prev.filter((item) => item !== companyNumber);
      } else {
        return [...prev, companyNumber];
      }
    });
  };

  const isAllSelected =
    advisorsRegionData &&
    advisorsRegionData.length > 0 &&
    advisorsRegionData
      .find((branch) => branch.branchCode === selectedBranch)
      ?.advisors.every((advisor) =>
        selectedAdvisors.includes(advisor.companyNumber)
      );

  const columns: ColumnsType<RegionDataType> = [
    {
      title: (
        <Checkbox
          checked={isAllSelected}
          indeterminate={selectedAdvisors.length > 0 && !isAllSelected}
          onChange={handleTitleCheckboxChange}
        />
      ),
      width: 50,
      key: "companyNumber",
      dataIndex: "companyNumber",
      render: (companyNumber: string) => (
        <Checkbox
          checked={selectedAdvisors.includes(companyNumber)}
          onChange={() => handleRowCheckboxChange(companyNumber)}
        />
      ),
    },
    {
      title: "담당자",
      width: 100,
      key: "name",
      dataIndex: "name",
    },
    {
      title: "사번",
      width: 100,
      key: "companyNumber",
      dataIndex: "companyNumber",
    },
    {
      title: "담당 지역",
      key: "regions",
      dataIndex: "regions",
      render: (regions) => {
        return regions.map((region: string) => {
          const split = region.split("/");
          return (
            <Tag>
              {firstLevelKorean[split[0] as keyof typeof firstLevelKorean] +
                " " +
                secondLevelKorean[split[1] as keyof typeof secondLevelKorean]}
            </Tag>
          );
        });
      },
    },
    {
      title: "지역 설정",
      width: 100,
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => {
            setModalOpen(true);
            setSelectedAdvisors([record.companyNumber]);
          }}
        >
          지역 설정
        </Button>
      ),
    },
  ];

  useEffect(() => {
    fetchAdvisorsRegionData(false);
    fetchRegionsOptions();
  }, []);

  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "System Management"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div id="advisorRegionWrapper">
      <ContentTitle title="담당지역 관리" />
      <div className="content-container">
        <Tabs
          className="tabs"
          tabPosition="left"
          onChange={handleTabChange}
          defaultActiveKey="1"
          items={advisorsRegionData?.map((item, index) => {
            const id = String(index + 1);
            return {
              label: `${item.branchName}(${item.branchCode})`,
              key: id,
              children: (
                <div className="p-3">
                  <div className="flex justify-between">
                    <p className="title">{`${item.branchName}(${item.branchCode})`}</p>
                    <div className="flex">
                      <img
                        onClick={() => {
                          fetchAdvisorsRegionData(true);
                        }}
                        className="icon"
                        alt="refresh-icon"
                        src={require("../../../assets/icons/refresh.png")}
                      />
                      <img
                        className="icon"
                        alt="expand-icon"
                        src={require("../../../assets/icons/expand.png")}
                      />
                    </div>
                  </div>
                  <Button
                    className="mt-5"
                    disabled={selectedAdvisors.length === 0}
                    onClick={() => {
                      setModalOpen(true);
                    }}
                  >
                    담당지역 설정
                  </Button>
                  <Table
                    className="mt-5"
                    columns={columns}
                    rowClassName={(record: RegionDataType) =>
                      selectedAdvisors.includes(record.companyNumber)
                        ? "selected-row"
                        : ""
                    }
                    dataSource={
                      advisorsRegionData.find(
                        (branch) => branch.branchCode === selectedBranch
                      )?.advisors || []
                    }
                    scroll={{ x: "max-content", y: 400 }}
                  />
                </div>
              ),
            };
          })}
        />
      </div>
      <Modal
        width={400}
        centered
        okText="확인"
        cancelText="초기화"
        title="담당지역 설정"
        open={modalOpen}
        onOk={handleAdvisorsRegionChange}
        onCancel={() => {
          setModalOpen(false);
          setSelectedRegions([]);
        }}
      >
        <Cascader.Panel
          className="cascader"
          value={selectedRegions}
          multiple
          onChange={handleRegionChange}
          options={regionsOptions}
        />
        <div className="mt-4">
          {selectedRegions.map((region, i) => (
            <Tag>
              {`${
                firstLevelKorean[region[0] as keyof typeof firstLevelKorean]
              } ${
                region[1]
                  ? secondLevelKorean[
                      region[1] as keyof typeof secondLevelKorean
                    ]
                  : ""
              }`}
              <span
                className="ml-2"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const replica = [...selectedRegions];
                  replica.splice(i, 1);
                  setSelectedRegions(replica);
                }}
              >
                X
              </span>
            </Tag>
          ))}
        </div>
        <Divider />
      </Modal>
    </div>
  );
};

export default AdvisorRegion;
